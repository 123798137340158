import { Observable } from '../Observable';
import { innerFrom } from './innerFrom';
import { argsOrArgArray } from '../util/argsOrArgArray';
import { EMPTY } from './empty';
import { OperatorSubscriber } from '../operators/OperatorSubscriber';
import { popResultSelector } from '../util/args';
export function zip(...args) {
  const resultSelector = popResultSelector(args);
  const sources = argsOrArgArray(args);
  return sources.length ? new Observable(subscriber => {
    let buffers = sources.map(() => []);
    let completed = sources.map(() => false);
    subscriber.add(() => {
      buffers = completed = null;
    });
    for (let sourceIndex = 0; !subscriber.closed && sourceIndex < sources.length; sourceIndex++) {
      innerFrom(sources[sourceIndex]).subscribe(new OperatorSubscriber(subscriber, value => {
        buffers[sourceIndex].push(value);
        if (buffers.every(buffer => buffer.length)) {
          const result = buffers.map(buffer => buffer.shift());
          subscriber.next(resultSelector ? resultSelector(...result) : result);
          if (buffers.some((buffer, i) => !buffer.length && completed[i])) {
            subscriber.complete();
          }
        }
      }, () => {
        completed[sourceIndex] = true;
        !buffers[sourceIndex].length && subscriber.complete();
      }));
    }
    return () => {
      buffers = completed = null;
    };
  }) : EMPTY;
}
