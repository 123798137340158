
export interface TaskMaster {
    id: number;
    isActive: boolean;
    taskTitle: string;
    taskTypeDescription: string;
}

export enum TaskStatus {
    'Not Started' = 1,
    'In Progress' = 2,
    'Hold' = 3,
    'Problem' = 4,
    'Waiting' = 5,
    'Cancelled' = 8,
    'Completed' = 9,
    'Not Applicable' = 10
}

export interface JobTask { // implements DateSeries
    id: number;

    jobId: number;
    jobNumber: string;

    startDate: string;
    endDate: string;

    statusId: TaskStatus;
    allocatedTo: string;
    division: string;
    vendor: string;
    salesRep: string;
    siteManager: string;
}
