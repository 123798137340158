import { Injectable, EventEmitter, Injector } from '@angular/core'
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { throwError as observableThrowError } from 'rxjs'
import { v4 as uuid_v4 } from 'uuid'

import { AuthService } from './auth.service'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root',
})
export class HttpHelperService {
  clearCaches = new EventEmitter<null>()

  sessionId = uuid_v4()
  companyId = ''
  userId = '0'

  constructor(private injector: Injector) {
    const auth = this.injector.get(AuthService)
    auth.currentCompany.subscribe((comp) => {
      this.clearCaches.emit()
      if (comp?.id) {
        this.companyId = comp.id.toString()
      }
    })
    auth.currentUser.subscribe((user) => {
      if (user?.id) {
        this.userId = user.id.toString()
      }
    })
  }

  get httpOptions(): { headers: HttpHeaders } {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id': environment.clientName,
        'client-session-id': this.sessionId,
        'client-version': environment.fullVersion,
        'company-id': this.companyId,
        'user-id': this.userId,
      }),
    }
    return httpOptions
  }

  get httpFileOptions(): { headers: HttpHeaders } {
    const httpOptions = {
      headers: new HttpHeaders({
        'client-id': environment.clientName,
        'client-session-id': this.sessionId,
        'client-version': environment.fullVersion,
        'company-id': this.companyId,
        'user-id': this.userId,
      }),
    }
    return httpOptions
  }

  handleHTTPError(err: HttpErrorResponse) {
    console.error(JSON.stringify(err))
    return observableThrowError(err)
  }
}
