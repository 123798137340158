<dx-popup class="popup" [width]="500" [height]="700" [showTitle]="true" title="Topics" [dragEnabled]="false"
  [hideOnOutsideClick]="true" [visible]="true" [showCloseButton]="true" (onHiding)="onHiding()">
  <div *dxTemplate="let data of 'content'">
    <dx-data-grid id="dataGrid" [dataSource]="dataSource" [height]="590" [allowColumnReordering]="true"
      [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
      [showColumnLines]="true">

      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>
      <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
        refreshMode="reshape">
      </dxo-editing>
      <dxo-paging [enabled]="false"></dxo-paging>

      <dxi-column dataField="description" sortOrder="asc">
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>
    </dx-data-grid>
  </div>
</dx-popup>
