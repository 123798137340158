import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Holiday } from 'src/app/types/dto/holiday.dto';
import { environment } from 'src/environments/environment';
import { HttpHelperService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class HolidaysApiService {
  
  constructor(
    private httpHelper: HttpHelperService,
    private http: HttpClient) { }

  getHolidays(): Observable<Holiday[]> {
    const url = environment.apiUrl + 'holidays';
    return this.http.get<Holiday[]>(url, this.httpHelper.httpOptions);
  }

}
