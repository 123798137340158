export enum CardSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

// only append to the end to preserve DB integrity!
export enum ChartDataSource {
  Tasks,
  Claims,
  Jobs,
  TaskDurations,
  Cashflow,
  ForecastedTasks,
  ForecastedTasksInProgress,
}

// only append to the end to preserve DB integrity!
export enum ChartType {
  Bar,
  Line,
}

// only append to the end to preserve DB integrity!
export enum DateAxisType {
  Weekly,
  Monthly,
  Yearly,
  Daily,
}

// only append to the end to preserve DB integrity!
export enum ValueAggregationType {
  // Count -> the effect when DefaultValueFieldOption is selected as the value field
  Sum,
  Average,
  Cumulative,
}

export enum DateRangeType {
  'Custom',
  'All Time',
  'Last Month',
  'Last 3 Months',
  'Last 6 Months',
  'Last 12 Months',
  'Last Year',
  'Last 5 Years',
  'Next Month',
  'Next 3 Months',
  'Next 6 Months',
  'Next Year',
  'Next 5 Years',
}

export enum TaskDurationAggregation {
  Day,
  BusinessDay,
  Week,
}
