import { Component, HostBinding, OnInit } from '@angular/core'
import { locale } from 'devextreme/localization'
import { ScreenService } from './shared/services'
import { NotificationService } from './shared/services/notification.service'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isIframe = false

  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes)
      .filter((cl) => this.screen.sizes[cl])
      .join(' ')
  }

  constructor(private screen: ScreenService, private notiService: NotificationService) {
    locale('AU')
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener

    const browser = window.navigator.userAgent.toLowerCase()
    if (browser.indexOf('firefox') > 0 && !environment.local) {
      this.notiService.showWarning(
        'While this app functions on Firefox, Chrome & Edge are the officially supported browsers.' +
          '<br><br> If you encounter any issues please test on these browsers before raising it with support.',
        'Warning',
        { timeOut: 0, enableHtml: true, positionClass: 'toast-bottom-full-width' }
      )
    }
  }
}
