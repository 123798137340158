<div mat-dialog-title class="modal-header">
  Clone Chart
</div>

<mat-dialog-content *ngIf="form" [formGroup]="form" class="p-t-20">
  <mat-form-field appearance="fill" class="full-width"
    [ngClass]="{'err' : input.value && input.value.length > MAX_NAME_LEN}">
    <mat-label>Name</mat-label>
    <input #input formControlName="name" matInput cdkFocusInitial>
    <mat-hint align="end" [ngClass]="{'err' : input.value && input.value.length > MAX_NAME_LEN}">
      {{input.value.length || 0}}/{{MAX_NAME_LEN}}
    </mat-hint>
  </mat-form-field>

  <mat-form-field appearance="fill" class="full-width m-t-10">
    <mat-label>Dashboard</mat-label>
    <mat-select formControlName="dashboard">
      <mat-option *ngFor="let source of dashboards" [value]="source">
        {{source.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<div mat-dialog-actions align="end" class="modal-footer">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-stroked-button color="primary"
    (click)="save()" [disabled]="form?.invalid || loading">
    Save
  </button>
</div>
