import {
  ChartDataSource,
  ChartType,
  DateAxisType,
  TaskDurationAggregation,
  ValueAggregationType,
} from 'src/app/types/enums'

export interface GenericChartItem {
  groupField: any

  numItems: number
  value: number

  // optional dynamic values for each custom/dynamic series grouping
  // dx does not supported nested paths when defining the 'valueField' of a series
  // so we have to do this instead of including the values in the below array :(
  [key: string]: any
  // the number of items in each custom series
  seriesNumItems: Record<string, number>

  hideNumItems?: boolean // hide default num items row on point-info. info likely in additionalPointInfoValues
  additionalPointInfoValues?: ChartValue[]
  jobIds: string[]
}
export const KNOWN_CHART_ITEM_FIELDS = [
  'groupField',
  'numItems',
  'value',
  'seriesNumItems',
  'hideNumItems',
  'additionalPointInfoValues',
  'jobIds',
]

// rename chartSeries ??
export interface ChartValue {
  label: string
  value: number | string
  isNumber?: boolean
  notBold?: boolean
}

export interface ChartConfig {
  cardTitle: string

  dataSourceId: ChartDataSource

  /** a number field on object to chart on y axis.
        if null or DEFAULT_VALUE_FIELD,
        charts will use the number of matched objects */
  valueField: string
  valueAggregationTypeId: ValueAggregationType
  valueAxisTitle?: string
  /** only applies with value aggregation type 'cumulative', and if not splitting/multiple series */
  startingValue: number

  /** field to group objects by on x axis */
  argumentField: string
  argumentFieldIsDate: boolean
  /** split by this field to create separate series on the chart.
   *  has value of DISABLED_SEPARATE_SERIES if not splitting */
  splitByField: string

  /** only show aggregation and splitting categories that exist in filtered data
   *  i.e. filter by date range and any custom filters before generating list of chart series + creating (non-date) aggregate groups
   *  doesn't apply to task-duration charts yet */
  showFilteredCategoriesOnly: boolean

  dateAxisTypeId: DateAxisType
  dateRangeTypeId?: number
  dateFilterField?: string
  dateFrom?: Date
  dateTo?: Date

  chartTypeId: ChartType
  filters?: string
  chartColour?: string
  targetValue?: number

  /** used by some datasources */
  taskMasterId?: number

  /** specific to task-duration datasource */
  taskMaster2Id?: number
  /** specific to task-duration datasource */
  task1DateField?: string
  /** specific to task-duration datasource */
  task2DateField?: string
  /** specific to task-duration datasource */
  taskDurationAggregation: TaskDurationAggregation

  /** specific to cashflow datasource */
  expectedPercentCancellation?: number

  /** hides the 'sum/average of' and 'number of items'
        lines in the chart tooltip
        i.e. when GenericChartItem's 'additionalValues' are to be used exclusively */
  hideDefaultValueTooltipLines?: boolean

  /** can be in HTML */
  tooltipTitle?: string
}

export interface ChartField {
  dataField: string
  displayName: string
  dataType: 'date' | 'string' | 'number' | 'boolean'
  isDefault?: boolean
}
