import { ChartField } from 'src/app/types/chart.model'
import {
  JOBNUM_FIELD,
  JOBNUM_LABEL,
  NUM_MATCHED_ITEMS_VALUE_FIELD,
} from 'src/app/types/constants'

export interface ProcessedClaim {
  id: number

  jobNumber: string

  invoiceDate: Date
  dueDate: Date
  postedDate: Date

  totalExGST: number
  totalIncGST: number
  totalGST: number

  jobId: number
  type: string
  status: string
  division: string
}

export const CLAIM_FILTER_FIELDS: ChartField[] = [
  {
    dataField: JOBNUM_FIELD,
    displayName: JOBNUM_LABEL,
    dataType: 'string',
  },
  {
    dataField: 'type',
    displayName: 'Type',
    dataType: 'string',
  },
  {
    dataField: 'status',
    displayName: 'Status',
    dataType: 'string',
  },
  {
    dataField: 'division',
    displayName: 'Division',
    dataType: 'string',
  },
  {
    dataField: 'totalIncGST',
    displayName: 'Total Inc GST',
    dataType: 'number',
  },
  {
    dataField: 'siteManager',
    displayName: 'Site Manager',
    dataType: 'string',
  },
]

export const CLAIM_ARGUMENT_FIELDS: ChartField[] = [
  {
    dataField: JOBNUM_FIELD,
    displayName: JOBNUM_LABEL,
    dataType: 'string',
  },
  {
    dataField: 'invoiceDate',
    displayName: 'Invoice Date',
    dataType: 'date',
  },
  {
    dataField: 'dueDate',
    displayName: 'Due Date',
    dataType: 'date',
  },
  {
    dataField: 'createDate',
    displayName: 'Posted Date',
    dataType: 'date',
  },
  {
    dataField: 'type',
    displayName: 'Type',
    dataType: 'string',
  },
  {
    dataField: 'status',
    displayName: 'Status',
    dataType: 'string',
  },
  {
    dataField: 'division',
    displayName: 'Division',
    dataType: 'string',
  },
  {
    dataField: 'siteManager',
    displayName: 'Site Manager',
    dataType: 'string',
  },
]

export const CLAIM_VALUE_FIELDS: ChartField[] = [
  NUM_MATCHED_ITEMS_VALUE_FIELD,
  {
    dataField: 'totalIncGST',
    displayName: 'Total Inc GST',
    dataType: 'number',
  },
  {
    dataField: 'totalExGST',
    displayName: 'Total Ex GST',
    dataType: 'number',
  },
  {
    dataField: 'totalGST',
    displayName: 'Total GST',
    dataType: 'number',
  },
]

export const CLAIM_DATE_FILTER_FIELDS: ChartField[] = [
  {
    dataField: 'invoiceDate',
    displayName: 'Invoice Date',
    dataType: 'date',
  },
  {
    dataField: 'dueDate',
    displayName: 'Due Date',
    dataType: 'date',
  },
  {
    dataField: 'createDate',
    displayName: 'Posted Date',
    dataType: 'date',
  },
]

export const CLAIM_SERIES_GROUP_FIELDS: ChartField[] = CLAIM_FILTER_FIELDS.filter(
  (jf) => jf.dataType === 'string' || jf.dataType === 'boolean'
)
