import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core'
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular'
import { InteractionStatus, RedirectRequest, EventMessage, EventType, AuthenticationResult } from '@azure/msal-browser'
import { filter, takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { environment } from 'src/environments/environment'
import { NotificationService } from '../../services/notification.service'
import { UtilsService } from '../../services/utils.service'
import { AuthService } from '../../services/auth.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  isHttps = true
  showLoginButton = false
  containerHeight = window.innerHeight - 100
  private readonly _destroying$ = new Subject<void>()
  showRefreshButton = false
  isAlive = true

  @HostListener('window:resize') onResize() {
    setTimeout(() => {
      this.containerHeight = window.innerHeight - 100
    }, 1000) // wait for iPhone
  }

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private auth: AuthService,
    private notiService: NotificationService,
    private utils: UtilsService,
    private router: Router
  ) {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoggedIn();
      });
  }

  ngOnInit() {
    // will get cryptic msal errors if trying to login from http
    if (!this.utils.isHttps()) {
      this.isHttps = false
    }

    this.msalService.handleRedirectObservable().subscribe();
    this.setLoginDisplay();

    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  setLoginDisplay() {
    this.showLoginButton = this.msalService.instance.getAllAccounts().length <= 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginClicked() {
    this.showLoginButton = false
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }


    // this.showLoginButton = false
    // this.activelyLoggingIn = true
    // if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
    //   this.msalService
    //     .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
    //     .subscribe((response: AuthenticationResult) => {
    //       this.msalService.instance.setActiveAccount(response.account)
    //     })
    // } else {
    //   this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest)
    // }
  }

  goToHttps() {
    window.location.href = environment.applicationUrl;
  }

  ngOnDestroy() {
    this._destroying$.next(undefined)
    this._destroying$.complete()
    this.isAlive = false
  }

  refresh(): void {
    window.location.reload()
  }

  clearCache() {
    localStorage.clear()
    this.notiService.showInfo('Cache cleared. Logging out...')
    setTimeout(() => {
      this.auth.signOut()
    }, 2000)
  }

  setLoggedIn() {
    const accounts = this.msalService.instance.getAllAccounts();
    const email = accounts[0]?.idTokenClaims?.emails?.[0];
    const user = { email: email };
    this.auth.setCurrentUser(user);
    this.router.navigateByUrl('home');
  }
}
