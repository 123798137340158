import { DashCardDto } from './dto/dashboard.types.dto'
import {
  CardSize,
  ChartDataSource,
  ChartType,
  DateAxisType,
  DateRangeType,
  TaskDurationAggregation,
  ValueAggregationType,
} from './enums'

export class AppMatCard implements DashCardDto {
  cols: number
  rows: number

  id: number
  size: CardSize
  title: string

  ///  CHART - mapped to ChartConfig interface ///

  dataSourceId?: ChartDataSource

  /** a number field on object to chart on y axis.
        if null or DEFAULT_VALUE_FIELD,
        charts will use the number of matched objects */
  valueField: string
  valueAggregationTypeId: ValueAggregationType
  /** only applies with value aggregation type 'cumulative */
  startingValue: number

  /** field to group objects by on x axis */
  argumentField: string

  splitByField: string

  /** only show aggregation and splitting categories that exist in filtered data
   *  i.e. filter by date range and any custom filters before generating list of chart series + creating (non-date) aggregate groups
   *  doesn't apply to task-duration charts yet  */
  showFilteredCategoriesOnly: boolean

  dateRangeTypeId?: DateRangeType
  dateFilterField?: string
  dateFrom?: Date
  dateTo?: Date
  dateAxisTypeId?: DateAxisType

  chartTypeId?: ChartType
  filters?: string
  chartColour?: string
  targetValue?: number

  /** used by some datasources */
  taskMasterId?: number

  /** specific to task-duration datasource */
  taskMaster2Id?: number
  /** specific to task-duration datasource */
  task1DateField?: string
  /** specific to task-duration datasource */
  task2DateField?: string
  /** specific to task-duration datasource */
  taskDurationAggregation: TaskDurationAggregation

  /** specific to cashflow datasource */
  expectedPercentCancellation?: number

  constructor(card: DashCardDto, cols: number, rows: number) {
    this.cols = cols
    this.rows = rows

    this.argumentField = card.argumentField
    this.chartColour = card.chartColour
    this.chartTypeId = card.chartTypeId
    this.dataSourceId = card.dataSourceId
    this.dateAxisTypeId = card.dateAxisTypeId
    this.dateFilterField = card.dateFilterField
    this.dateFrom = card.dateFrom
    this.dateRangeTypeId = card.dateRangeTypeId
    this.dateTo = card.dateTo
    this.expectedPercentCancellation = card.expectedPercentCancellation
    this.filters = card.filters
    this.id = card.id
    this.showFilteredCategoriesOnly = card.showFilteredCategoriesOnly
    this.size = card.size
    this.splitByField = card.splitByField
    this.startingValue = card.startingValue
    this.targetValue = card.targetValue
    this.task1DateField = card.task1DateField
    this.task2DateField = card.task2DateField
    this.taskDurationAggregation = card.taskDurationAggregation
    this.taskMaster2Id = card.taskMaster2Id
    this.taskMasterId = card.taskMasterId
    this.title = card.title
    this.valueAggregationTypeId = card.valueAggregationTypeId
    this.valueField = card.valueField
  }
}
