import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpHelperService } from '../http.service';
import { ApplicationVersion } from 'src/app/types/dto/application-version.dto';
import { AreaPermission } from 'src/app/types/dto/area-permission.dto';
import { CompanyDto } from 'src/app/types/dto/company.dto';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  authorisedCompanies?: CompanyDto[];

  appBaseURL = environment.apiUrl + 'applications/';

  constructor(
    private http: HttpClient,
    private httpHelper: HttpHelperService) { }

  getCurrentApplicationVersion(): Observable<ApplicationVersion> {
    const url = this.appBaseURL + environment.appId + 'latest-version';
    return this.http.get<ApplicationVersion>(url, this.httpHelper.httpOptions);
  }

  getAreaPermissions(): Observable<AreaPermission[]> {
    const url = this.appBaseURL + '1' + 'area-permissions/me';
    return this.http.get<AreaPermission[]>(url, this.httpHelper.httpOptions);
  }

  getAuthorisedCompanies(email: string): Observable<CompanyDto[]> {
    const url = environment.apiUrl + 'email-addresses/' + email.replace('@', '%40')
        + '/authorised-companies';

    if (this.authorisedCompanies) {
      return of(this.authorisedCompanies);
    } else {
      return this.http.get<CompanyDto[]>(url, this.httpHelper.httpOptions)
        .pipe(tap(comps => this.authorisedCompanies = comps));
    }
  }

  getCompanyPermission(compId: number, userId: number) {
    const url = environment.apiUrl + 'users/' + userId + '/companies/' + compId + '/user-permissions';
    return this.http.get(url, this.httpHelper.httpOptions);
  }
}
