import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingComponent } from './training.component';
import { SharedModule } from '../../shared/shared.module';
import { DxButtonModule, DxDataGridModule, DxPopupModule, DxSelectBoxModule } from 'devextreme-angular';
import { TrainingTopicsComponent } from './training-topics/training-topics.component';
import { TrainingCategoriesComponent } from './training-categories/training-categories.component';
import { TrainingCoursesComponent } from './training-courses/training-courses.component';
import { TrainingLevelsComponent } from './training-levels/training-levels.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DxButtonModule,
    DxDataGridModule,
    DxPopupModule,
    DxSelectBoxModule,
  ],
  declarations: [
    TrainingComponent,
    TrainingTopicsComponent,
    TrainingCategoriesComponent,
    TrainingCoursesComponent,
    TrainingLevelsComponent
  ]
})
export class TrainingModule { }
