<div *ngIf="!loading" class="welcome">
  <div class="chooseComp">
    <mat-form-field *ngIf="multipleCompanies" class="semi-bold m-t-15 w-300">
      <mat-label>{{currentComp ? 'Company' : 'Please Select a Company'}}</mat-label>
      <mat-select [(value)]="currentComp" (selectionChange)="compSelected()"
        [compareWith]="compareComps" [disabled]="!multipleCompanies" name="compSelect">
        <mat-option *ngFor="let comp of userCompanies" [value]="comp">
          {{comp.companyName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngIf="!multipleCompanies" class="info-box one-rem">
      {{currentComp?.companyName}}
    </div>
  </div>
  
  <div class="user">
    <div class="userDetails">
      <div style="text-align: left;">
        <span *ngIf="currentUser"class="username">
          {{currentUser.firstName}} {{currentUser.lastName}}
        </span>
        <span *ngIf="!currentUser"class="username">User</span>
      </div>
      {{currentUserEmail}}
    </div>
    <div class="userButtons">
      <button mat-stroked-button color="primary" (click)="signOut()" class="sign-out-btn">
        Sign out
      </button>
    </div>
  </div>

  <div *ngIf="appVersion" class='versionString'>
    version <span class="semi-bold">{{appVersion}}</span>
  </div>
</div>

<app-spinner *ngIf="loading"></app-spinner>