
<app-spinner *ngIf="loading; else loaded"></app-spinner>

  <ng-template #loaded>
    <mat-grid-list *ngIf="!error" [cols]="dashService.cols" rowHeight="400px">
      <mat-grid-tile *ngFor="let card of dashService.displayCards; let i = index"
        [colspan]="card.cols" [rowspan]="card.rows">
        <app-dash-card [cardInfo]="card" [index]="i"></app-dash-card>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="dashService.addChartCols" rowspan="1">
        <dx-button class="add-chart-btn" (onClick)="dashService.addCard()">
          <mat-icon class="add-chart-icon">add</mat-icon>
        </dx-button>
      </mat-grid-tile>
    </mat-grid-list>

    <div *ngIf="error" class="error-box m-50">
      There was an error loading the dashboard.
      <br> Please try again in a moment.
    </div>
  </ng-template>