<div class="grid-container">
  <dx-button *ngIf="isSuperUser" (onClick)="editTopics()">Topics</dx-button>
  <dx-button *ngIf="isSuperUser" class="buttonSpacing" (onClick)="editCategories()">Categories</dx-button>
  <dx-button *ngIf="isSuperUser" class="buttonSpacing" (onClick)="editCourses()">Courses</dx-button>
  <dx-button *ngIf="isSuperUser" class="buttonSpacing" (onClick)="editLevels()">Levels</dx-button>

  <!-- Training documents grid -->
  <dx-data-grid id="dataGrid" [dataSource]="dataSource" [height]="gridHeight" [allowColumnReordering]="true"
    [allowColumnResizing]="true" [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    [showColumnLines]="true">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-search-panel [visible]="true" [width]="300"></dxo-search-panel>
    <dxo-editing mode="row" [allowAdding]="isSuperUser" [allowUpdating]="isSuperUser" [allowDeleting]="isSuperUser" [useIcons]="true"
      refreshMode="reshape">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="description" caption="Training Document" sortOrder="asc">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="trainingCourseId" caption="Course" dataType="number" [width]="250">
      <dxo-lookup [dataSource]="trainingCourses" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="trainingLevelId" caption="Level" dataType="number" [width]="80">
      <dxo-lookup [dataSource]="trainingLevels" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="trainingTopicId" caption="Topic" dataType="number" [width]="150">
      <dxo-lookup [dataSource]="trainingTopics" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>
  </dx-data-grid>
</div>

<app-training-topics *ngIf="showTopics" (closePopup)="closePopup()"></app-training-topics>
<app-training-categories *ngIf="showCategories" (closePopup)="closePopup()"></app-training-categories>
<app-training-courses *ngIf="showCourses" (closePopup)="closePopup()"></app-training-courses>
<app-training-levels *ngIf="showLevels" (closePopup)="closePopup()"></app-training-levels>
