import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CardApiService } from 'src/app/shared/services/api/card.api.service'
import { LogService } from 'src/app/shared/services/log.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { AppMatCard } from 'src/app/types/dashboard.model'
import { DashBoardLite } from 'src/app/types/dto/dashboard.types.dto'
import { CloneCardRequest } from 'src/app/types/request/dashboard.req'
import { MAX_DASHBOARD_NAME_LEN } from 'src/config/global-config'
import { DashboardService } from '../dashboard/dashboard.service'
import { DashboardsService } from '../dashboards.service'

@Component({
  selector: 'app-clone-card',
  templateUrl: './clone-card.component.html',
  styleUrls: [],
})
export class CloneCardComponent implements OnInit {
  MAX_NAME_LEN = MAX_DASHBOARD_NAME_LEN
  card?: AppMatCard
  dashboards?: DashBoardLite[]
  form?: UntypedFormGroup
  loading = false

  constructor(
    private dashboardsService: DashboardsService,
    private dashboardService: DashboardService,
    private cardApi: CardApiService,
    private notiService: NotificationService,
    private logger: LogService,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<CloneCardComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.card = data.card
  }

  ngOnInit() {
    this.dashboards = this.dashboardsService.dashboards
    this.initForm()
  }

  save() {
    if (!this.card || !this.form) {
      this.logger.log('clone-card', 'save', null, 'no card or form')
      throw Error('no card or form in clone-card save')
    }

    this.loading = true
    this.form?.disable({ emitEvent: false })

    const req = {
      cardId: this.card.id,
      title: this.form.controls.name.value,
      dashboardId: this.form.controls.dashboard.value.id,
    } as CloneCardRequest

    this.cardApi.cloneCard(req).subscribe({
      next: () => {
        if (req.dashboardId === this.dashboardService.dashboard?.id)
          this.dashboardService.refresh()

        this.notiService.showSuccess('Chart Cloned')
        this.dialogRef.close()
      },
      error: (err) => {
        this.loading = false
        this.form?.enable()
        this.notiService.handleError(err)
        this.logger.log('edit-card', 'save', err)
      },
    })
  }

  initForm() {
    if (!this.card) {
      this.logger.log('clone-card', 'initForm', null, 'no card')
      throw Error('no card in clone-card')
    }

    this.form = this.formBuilder.group({
      name: [
        this.card.title + ' (cloned)',
        [Validators.maxLength(this.MAX_NAME_LEN), Validators.minLength(2)],
      ],
      dashboard: [null, Validators.required],
    })
  }
}
