import { ChartField } from '../chart.model'
import { JOBNUM_FIELD, JOBNUM_LABEL } from '../constants'
import { ValueAggregationType } from '../enums'

export const CASHFLOW_DEFAULT_VALUE_AGGREGATION = ValueAggregationType.Cumulative

export const CASHFLOW_TOOLTIP_VALUE_STRING = 'Cash Flow'

export const CASHFLOW_FILTER_FIELDS: ChartField[] = [
  {
    dataField: JOBNUM_FIELD,
    displayName: JOBNUM_LABEL,
    dataType: 'string',
  },
  {
    dataField: 'division',
    displayName: 'Division',
    dataType: 'string',
  },
  {
    dataField: 'jobDescription',
    displayName: 'Job Description',
    dataType: 'string',
  },
  {
    dataField: 'taskDescription',
    displayName: 'Task Description',
    dataType: 'string',
  },
  {
    dataField: 'isPracticalCompletionTask',
    displayName: 'Is Practical Completion Task',
    dataType: 'boolean',
  },
  {
    dataField: 'isForecastStart',
    displayName: 'Is Forecast Start',
    dataType: 'date',
  },
]

export const CASHFLOW_ARGUMENT_FIELDS: ChartField[] = [
  {
    dataField: 'forecastPaymentDate',
    displayName: 'Forecast Payment Date',
    dataType: 'date',
  },
]

export const CASHFLOW_VALUE_FIELDS: ChartField[] = [
  {
    dataField: 'totalFlow',
    displayName: 'Total Flow',
    dataType: 'number',
  },
]

export const CASHFLOW_DATE_FILTER_FIELDS: ChartField[] = [
  {
    dataField: 'forecastPaymentDate',
    displayName: 'Forecast Payment Date',
    dataType: 'date',
    isDefault: true,
  },
  {
    dataField: 'forecastCompletionDate',
    displayName: 'Forecast Completion Date',
    dataType: 'date',
  },
  {
    dataField: 'forecastStartDate',
    displayName: 'Forecast Start Date',
    dataType: 'date',
  },
]

export const CASHFLOW_SERIES_GROUP_FIELDS: ChartField[] = CASHFLOW_FILTER_FIELDS.filter(
  (jf) => jf.dataType === 'string' || jf.dataType === 'boolean'
)
