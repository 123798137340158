<div mat-dialog-title class="modal-header">
 {{adding ? 'Add Dashboard' : 'Edit Dashboard'}}
</div>

<mat-dialog-content *ngIf="form" [formGroup]="form" class="p-t-20">
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Title</mat-label>
    <input #input formControlName="name" matInput>
    <mat-hint align="end">{{input.value.length || 0}}/{{MAX_NAME_LEN}}</mat-hint>
  </mat-form-field>
  <mat-checkbox class="m-t-10 m-b-24" formControlName="isDefault"
    [checked]="isDefaultDashboard" (change)="isDefaultDashboard = !isDefaultDashboard">
    Default dashboard (for you only)
  </mat-checkbox>
</mat-dialog-content>

<div mat-dialog-actions align="end" class="modal-footer">
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button *ngIf="!adding" mat-stroked-button color="warn" (click)="delete()">
    Delete
  </button>
  <button mat-stroked-button cdkFocusInitial color="primary"
    (click)="save()" [disabled]="form?.invalid">
    Save
  </button>
</div>
