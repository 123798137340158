import { NotificationService } from './../../shared/services/notification.service';
import { AuthService } from './../../shared/services/auth.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { TrainingService } from './training.service';
import CustomStore from 'devextreme/data/custom_store';
import { TrainingLevelDto } from '../../types/dto/training-level';
import { TrainingCategoryDto } from '../../types/dto/training-category';
import { TrainingCourseDto } from '../../types/dto/training-course';
import { TrainingTopicDto } from '../../types/dto/training-topic.dto';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {
  showTopics = false;
  isSuperUser: boolean = false;
  showCategories: boolean = false;
  showCourses: boolean = false;
  showLevels: boolean = false;
  dataSource: CustomStore = {} as CustomStore;
  gridHeight: number = 400;
  loading: boolean = true;
  trainingLevels: TrainingLevelDto[] = [];
  trainingCategories: TrainingCategoryDto[] = [];
  trainingCourses: TrainingCourseDto[] = [];
  trainingTopics: TrainingTopicDto[] = [];

  @HostListener('window:resize') onResize() {
    setTimeout(() => {
      this.setWidths();
    }, 500); // wait for iPhone
  }

  constructor(private authService: AuthService,
    private trainingService: TrainingService,
    private notiService: NotificationService) { }

  ngOnInit() {
    this.isSuperUser = this.authService.isSuperUser() ?? false;
    this.setWidths();
    this.getData();
  }

  setWidths() {
    this.gridHeight = this.isSuperUser ? window.innerHeight - 130 : window.innerHeight - 90;
  }

  editTopics() {
    this.showTopics = true;
  }

  editCategories() {
    this.showCategories = true;
  }

  editCourses() {
    this.showCourses = true;
  }

  editLevels() {
    this.showLevels = true;
  }

  closePopup() {
    this.showTopics = false;
    this.showCategories = false;
    this.showCourses = false;
    this.showLevels = false;
  }

  getData() {
    this.trainingService.getTrainingData().subscribe({
      next: () => {
        this.trainingLevels = this.trainingService.trainingLevels;
        this.trainingCategories = this.trainingService.trainingCategories;
        this.trainingCourses = this.trainingService.trainingCourses;
        this.trainingTopics = this.trainingService.trainingTopics;
        this.loading = false;
        this.setUpDataSource();
      },
      error: (err: any) => {
        this.loading = false;
        this.notiService.handleError(err);
      },
    });
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.trainingService.getTrainingDocuments().subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.trainingService.returnError(err));
          }));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.trainingService.addTrainingDocument(values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.trainingService.returnError(err));
          }));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.trainingService.updateTrainingDocument(encodeURIComponent(key), values).subscribe(res => {
            return resolve(res);
          }, err => {
            return reject(this.trainingService.returnError(err));
          }));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.trainingService.deleteTrainingDocument(encodeURIComponent(key)).subscribe(() => {
            return resolve();
          }, err => {
            return reject(this.trainingService.returnError(err));
          }));
      }
    });
  }
}
