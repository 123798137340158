import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DxTooltipModule } from 'devextreme-angular'
import { MatButtonModule } from '@angular/material/button'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatIconModule } from '@angular/material/icon'
import { MatDialogModule } from '@angular/material/dialog'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { ToastrModule } from 'ngx-toastr'
import { SpinnerComponent } from './components/spinner/spinner.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { DecimalMaskPipe } from './decimal-place.pipe'
import { ConfirmComponent } from './components/confirm/confirm.component'
import { DatasourceNamePipe } from './data-source-name.pipe'

@NgModule({
  declarations: [ConfirmComponent, SpinnerComponent, DatasourceNamePipe, DecimalMaskPipe],
  exports: [
    // npm
    ToastrModule,
    // dx
    DxTooltipModule,
    // mat
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    MatSelectModule,
    MatFormFieldModule,
    // app
    SpinnerComponent,
    DatasourceNamePipe,
    DecimalMaskPipe,
  ],
  imports: [
    // ng
    CommonModule,
    // npm
    ToastrModule.forRoot(),
    // dx
    DxTooltipModule,
    // mat
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    MatSelectModule,
    MatFormFieldModule,
  ],
  providers: [DatasourceNamePipe, DecimalMaskPipe, provideHttpClient(withInterceptorsFromDi())],
})
export class SharedModule {}
