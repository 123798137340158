import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ProcessedClaim } from 'src/app/types/datasources/claims.model'
import {
  IncomeInvoiceDto,
  IncomeInvoiceStatus,
  IncomeInvoiceType,
} from 'src/app/types/dto/income-invoice.dto'
import { environment } from 'src/environments/environment'
import { AuthService } from '../auth.service'
import { HttpHelperService } from '../http.service'
import { LogService } from '../log.service'
import { TimedCache } from '../timed-cache'

@Injectable({
  providedIn: 'root',
})
export class ClaimsApiService {
  private cachedClaims = new TimedCache<ProcessedClaim[]>()

  constructor(
    private httpHelper: HttpHelperService,
    private auth: AuthService,
    private logger: LogService,
    private http: HttpClient
  ) {}

  getClaims(): Observable<ProcessedClaim[]> {
    const comp = this.auth.getCurrentCompany()
    if (!comp) {
      this.logger.log('claims.api', 'getClaims', null, 'no company')
      throw Error('no company set in getClaims')
    }

    if (this.cachedClaims.getCacheItem(comp.id)) {
      return this.cachedClaims.getCacheItem(comp.id)!
    }

    const url = environment.apiUrl + 'income-invoices?forDashboard=true'
    const obs$ = this.http.get<IncomeInvoiceDto[]>(url, this.httpHelper.httpOptions).pipe(
      map((res) =>
        res.map((c) => {
          return {
            ...c,
            status: IncomeInvoiceStatus[c.incomeInvoiceStatusId],
            type: IncomeInvoiceType[c.incomeInvoiceTypeId],
          }
        })
      )
    )

    return this.cachedClaims.setCacheItem(comp.id, obs$)
  }
}
