export enum UserTypeEnum {
  SuperUser = 0,
  Admin = 1,
  Office = 2,
  Associate = 3,
  Client = 4
}

export interface UserDto {
  id: number;
  email: string;
  password: string;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  isActive: boolean;
  isSuperUser: boolean;
  mobileNumber: string;
  userTypeId: UserTypeEnum;
  userType: string;
}
