import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import CustomStore from 'devextreme/data/custom_store'
import { TrainingService } from '../training.service'

@Component({
  selector: 'app-training-levels',
  templateUrl: './training-levels.component.html',
  styleUrls: ['./training-levels.component.css'],
})
export class TrainingLevelsComponent implements OnInit {
  @Output() closePopup: EventEmitter<boolean> = new EventEmitter<boolean>()

  dataSource: CustomStore = {} as CustomStore

  constructor(private trainingService: TrainingService) {}

  ngOnInit() {
    this.setUpDataSource()
  }

  onHiding() {
    this.closePopup.emit(true)
  }

  setUpDataSource() {
    this.dataSource = new CustomStore({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.trainingService.getTrainingLevels().subscribe(
            (res) => {
              return resolve(res)
            },
            (err) => {
              return reject(this.trainingService.returnError(err))
            }
          )
        )
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.trainingService.addTrainingLevel(values).subscribe(
            (res) => {
              return resolve(res)
            },
            (err) => {
              return reject(this.trainingService.returnError(err))
            }
          )
        )
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.trainingService.updateTrainingLevel(encodeURIComponent(key), values).subscribe(
            (res) => {
              return resolve(res)
            },
            (err) => {
              return reject(this.trainingService.returnError(err))
            }
          )
        )
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.trainingService.deleteTrainingLevel(encodeURIComponent(key)).subscribe(
            () => {
              return resolve()
            },
            (err) => {
              return reject(this.trainingService.returnError(err))
            }
          )
        )
      },
    })
  }
}
