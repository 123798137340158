import { NgModule } from '@angular/core'
import { Routes, RouterModule, InitialNavigation } from '@angular/router'
import { DashboardModule } from './pages/dashboard/dashboard.module'
import { DashboardsComponent } from './pages/dashboard/dashboards.component'
import { MsalGuard } from '@azure/msal-angular'
import { LoginComponent } from './shared/components/login/login.component'
import { BrowserUtils } from '@azure/msal-browser'
import { HomeComponent } from './pages/home/home.component'
import { RefreshGuard } from './shared/services/refresh-guard.service'
import { TrainingModule } from './pages/training/training.module'
import { TrainingComponent } from './pages/training/training.component'

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardsComponent,
    canActivate: [MsalGuard, RefreshGuard],
  },
  {
    path: 'training',
    component: TrainingComponent,
    canActivate: [MsalGuard, RefreshGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [RefreshGuard],
  },
  {
    path: 'auth', // msal callback
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'login',
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // useHash: true,
      // enableTracing: true,
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? ('enabled' as InitialNavigation)
          : undefined,
    }),
    DashboardModule,
    TrainingModule,
  ],
  providers: [MsalGuard],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
