import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpHelperService } from '../http.service';
import { environment } from 'src/environments/environment';
import { UserDto } from 'src/app/types/dto/user.dto';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    baseURL = environment.apiUrl + 'users/';

    constructor(
        private http: HttpClient,
        private httpHelper: HttpHelperService
    ) { }

    getCurrCompUsers(userTypeId: number | undefined): Observable<UserDto[]> {
        let url = environment.apiUrl + 'companies/users';
        if (userTypeId !== null) {
            url += '?userTypeId';
        }

        return this.http.get<UserDto[]>(url, this.httpHelper.httpOptions);
    }

    getUser(id: number): Observable<UserDto> {
        return this.http.get<UserDto>(this.baseURL + id, this.httpHelper.httpOptions);
    }

    getUserByEmail(email: string) {
        return this.http.get<UserDto>(this.baseURL + email, this.httpHelper.httpOptions);
    }

}
