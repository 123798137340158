import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { ScreenService } from 'src/app/shared/services'
import { AppMatCard } from 'src/app/types/dashboard.model'
import { CardSize } from 'src/app/types/enums'
import { CloneCardComponent } from '../clone-card/clone-card.component'
import { DashboardService } from '../dashboard/dashboard.service'
import { EditCardComponent } from '../edit-card/edit-card.component'

@Component({
  selector: 'app-dash-card',
  templateUrl: './dash-card.component.html',
  styleUrls: ['./dash-card.component.scss'],
})
export class DashCardComponent implements OnChanges {
  @Input() cardInfo: AppMatCard | undefined
  @Input() index = 0

  isSmallCard = false
  isMedCard = false
  isLargeCard = false
  isReady = false

  constructor(
    public screen: ScreenService,
    public dashService: DashboardService,
    private dialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.isReady = false
    if (changes.cardInfo?.currentValue) {
      this.isSmallCard = changes.cardInfo.currentValue.size == CardSize.small
      this.isMedCard = changes.cardInfo.currentValue.size == CardSize.medium
      this.isLargeCard = changes.cardInfo.currentValue.size == CardSize.large
    }

    setTimeout(() => {
      this.isReady = true
    }, 200)
  }

  edit() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.panelClass = 'w-700'
    dialogConfig.data = {
      card: this.cardInfo,
    }

    this.dialog.open(EditCardComponent, dialogConfig)
  }

  clone() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.panelClass = 'w-700'
    dialogConfig.data = {
      card: this.cardInfo,
    }

    this.dialog.open(CloneCardComponent, dialogConfig)
  }

  makeLarger() {
    if (this.cardInfo) {
      this.dashService.makeCardLarger(this.cardInfo)
    }
  }

  makeSmaller() {
    if (this.cardInfo) {
      this.dashService.makeCardSmaller(this.cardInfo)
    }
  }

  move(moveLeft = false) {
    if (this.cardInfo) {
      this.dashService.moveCard(this.cardInfo, moveLeft)
    }
  }

  addRight() {
    if (this.cardInfo) {
      this.dashService.addCardRight(this.cardInfo)
    }
  }
}
