import { Injectable } from '@angular/core'
import DataSource from 'devextreme/data/data_source'
import { Observable, zip, from, of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import dayjs from 'dayjs'
import sameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(sameOrBefore)

import { ChartConfig, ChartValue, GenericChartItem } from 'src/app/types/chart.model'
import { ChartDataSource, DateRangeType, ValueAggregationType } from 'src/app/types/enums'
import { ClaimsApiService } from './api/claims.api.service'
import { JobsApiService } from './api/jobs.api.service'
import { TasksApiService } from './api/tasks.api.service'
import { DateUtilsService } from './date-utils.service'
import { TaskDurationService } from './task-duration.service'
import { UtilsService } from './utils.service'
import { DatasourceConfigService } from './datasource-config.service'
import { JobTaskForecastsService } from './api/job-task-forecasts.service'
import { DEFAULT_VALUE_FIELD, GROUP_KEYS_FUNCTION } from 'src/app/types/constants'
import { LogService } from './log.service'
import { AVERAGING_DECIMAL_PLACES_ROUNDING } from 'src/config/global-config'

export interface DateSeriesDate {
  startDate?: Date
  endDate?: Date
}

export interface ChartResult {
  data: GenericChartItem[]
  splitSeries: boolean

  dynamicSeries?: ChartValue[]
}

export interface FilteredItems {
  /** item has been filtered-out by date or custom filter.
   *  item is ignored when incrementing numItems or adding values
   *  relevant if ChartConfig.showFilteredCategoriesOnly = true
   */
  filteredIds: (number | string)[]

  items: any[]
}

export const WEEK_START = 1 // monday

export const SERIES_EMPTY_TEXT = 'NO DATA'

@Injectable({
  providedIn: 'root',
})
export class ChartDatasourceProviderService {
  constructor(
    private taskDurationService: TaskDurationService,
    private datasourceConfig: DatasourceConfigService,
    private tasksApi: TasksApiService,
    private claimsApi: ClaimsApiService,
    private jobsApi: JobsApiService,
    private cashFlowApi: JobTaskForecastsService,
    private dateUtils: DateUtilsService,
    private logger: LogService,
    private utils: UtilsService
  ) {}

  provideData(config: ChartConfig): Observable<ChartResult> {
    switch (config.dataSourceId) {
      case ChartDataSource.Tasks:
        return this.getTasksData(config)
      case ChartDataSource.TaskDurations:
        return this.getTaskDurationData(config)
      case ChartDataSource.Claims:
        return this.getClaimsData(config)
      case ChartDataSource.Jobs:
        return this.getJobsData(config)
      case ChartDataSource.Cashflow:
        return this.getCashflowData(config)
      case ChartDataSource.ForecastedTasks:
      case ChartDataSource.ForecastedTasksInProgress:
        return this.getForecastedTasksData(config)
      default:
        this.logger.log('chart-datasource-provider', 'provideData', 'data source not implemented')
        throw new Error('data source not implemented')
    }
  }

  private getForecastedTasksData(config: ChartConfig): Observable<ChartResult> {
    const obs$ = this.cashFlowApi.getRecalcCompletion().pipe(
      map((cashflow) => {
        return cashflow.filter((cf) => cf.taskMasterId === config.taskMasterId)
      })
    )

    return this.getData(config, obs$)
  }

  private getCashflowData(config: ChartConfig): Observable<ChartResult> {
    const obs$ = this.cashFlowApi.getCashflow(config.expectedPercentCancellation)

    return this.getData(config, obs$)
  }

  private getJobsData(config: ChartConfig): Observable<ChartResult> {
    const obs$ = this.jobsApi.getJobs()

    return this.getData(config, obs$)
  }

  private getClaimsData(config: ChartConfig): Observable<ChartResult> {
    const obs$ = this.claimsApi.getClaims()

    return this.getData(config, obs$)
  }

  private getTasksData(config: ChartConfig): Observable<ChartResult> {
    if (config.taskMasterId == null) {
      this.logger.log(
        'chart-datasource-provider',
        'getTasksData',
        'no task master id in chart config for tasks datasource'
      )
      throw new Error('no task master id in chart config for tasks datasource')
    }

    const obs$ = this.tasksApi.getTasksForTaskmaster(config.taskMasterId)

    return this.getData(config, obs$)
  }

  private getTaskDurationData(config: ChartConfig): Observable<ChartResult> {
    if (config.taskMasterId == null || config.taskMaster2Id == null) {
      this.logger.log(
        'chart-datasource-provider',
        'getTasksData',
        'no task master id in chart config for tasks datasource'
      )
      throw new Error('no task master ids in chart config for tasks datasource')
    }

    let obs$: Observable<any[]> = zip(
      this.tasksApi.getTasksForTaskmaster(config.taskMasterId),
      this.tasksApi.getTasksForTaskmaster(config.taskMaster2Id)
    ).pipe(map(([tasks1, tasks2]) => [tasks1, this.filterDates(config, tasks2)]))

    if (config.filters) {
      obs$ = obs$.pipe(
        mergeMap(([tasks1, tasks2]) =>
          this.filter(config, tasks2).pipe(map((filtered) => [tasks1, filtered]))
        )
      )
    }

    // remove unused filtered item info
    obs$ = obs$.pipe(map(([tasks1, tasks2]) => [tasks1, tasks2.items]))

    obs$ = obs$.pipe(
      mergeMap(([tasks1, tasks2]) => {
        return this.tasksApi.getGroupedTaskMastersForCurrentCompany().pipe(
          map((tasks) => {
            const task1Name = tasks.filter((t) => t.id === config.taskMasterId)[0]?.taskTitle
            const task2Name = tasks.filter((t) => t.id === config.taskMaster2Id)[0]?.taskTitle
            return [tasks1, tasks2, task1Name, task2Name]
          })
        )
      })
    )

    return obs$.pipe(
      map(([tasks1, tasks2, task1Name, task2Name]) =>
        this.taskDurationService.processTaskDurations(tasks1, tasks2, task1Name, task2Name, config)
      )
    )
  }

  private getData(config: ChartConfig, obs$: Observable<any>): Observable<ChartResult> {
    obs$ = obs$.pipe(map((res) => this.filterDates(config, res)))

    if (config.filters) {
      obs$ = obs$.pipe(mergeMap((res) => this.filter(config, res)))
    }

    return obs$.pipe(map((res) => this.groupData(res, config)))
  }

  /** use devextreme datasource to filter based on custom rules */
  private filter(config: ChartConfig, filteredItems: FilteredItems): Observable<FilteredItems> {
    const datasource = new DataSource({
      store: filteredItems.items,
      paginate: false,
      filter: config.filters ? JSON.parse(config.filters) : undefined,
    })
    datasource.load()
    console.debug(
      config.cardTitle + ' >> num filtered items in dx datasource ' + datasource.items().length
    )

    if (this.utils.showFilteredCategoriesOnly(config))
      return of({ filteredIds: [], items: datasource.items() })

    return from(datasource.store().load()).pipe(
      map((allItems: any) => {
        // inefficient =^_^=
        // hopefully way for dx-datasource to mark filtered items instead of removing them
        // or can implement the filtering ourselves
        const filtered = datasource.items()
        allItems.forEach((i: any) => {
          if (filtered.indexOf(i) < 0) filteredItems.filteredIds.push(i.id)

          return i
        })
        console.debug(
          config.cardTitle +
            ' >> num filtered after custom filters: ' +
            filteredItems.filteredIds.length
        )
        return filteredItems
      })
    )
  }

  private filterDates(config: ChartConfig, items: any[]): FilteredItems {
    console.debug(config.cardTitle + ' >> num items pre filters ' + items.length)
    if (!config.dateFilterField || config.dateRangeTypeId == null) {
      throw Error('tried to filter dates without a date filter field or date range type')
    }

    let startWindow: dayjs.Dayjs | undefined
    let endWindow: dayjs.Dayjs | undefined

    switch (config.dateRangeTypeId) {
      case DateRangeType.Custom:
        startWindow = dayjs(config.dateFrom)
        endWindow = dayjs(config.dateTo)
        break
      case DateRangeType['All Time']:
        break
      default: {
        const dates = this.dateUtils.getGroupingStartAndEndDate(config.dateRangeTypeId)
        startWindow = dates[0]
        endWindow = dates[1]
        break
      }
    }

    if (this.utils.showFilteredCategoriesOnly(config)) {
      const res = {
        filteredIds: [],
        items: items.filter((i) => {
          return (
            !startWindow ||
            (startWindow.isSameOrBefore(dayjs(i[config.dateFilterField!])) &&
              (!endWindow || dayjs(i[config.dateFilterField!]).isSameOrBefore(endWindow)))
          )
        }),
      }
      console.debug(config.cardTitle + ' >> num items after filter dates: ' + res.items.length)
      return res
    }

    const filteredIds: number[] = []
    items.forEach((i) => {
      const filteredOut =
        (startWindow! && !startWindow.isSameOrBefore(dayjs(i[config.dateFilterField!]))) ||
        (endWindow! && !dayjs(i[config.dateFilterField!]).isSameOrBefore(endWindow))
      if (filteredOut) filteredIds.push(i.id)
    })

    console.debug(config.cardTitle + ' >> num filtered after filter dates: ' + filteredIds.length)

    return {
      filteredIds: filteredIds,
      items: items,
    }
  }

  private groupData(filteredItems: FilteredItems, config: ChartConfig): ChartResult {
    const grpField = config.argumentField

    if (config.argumentFieldIsDate) {
      this.convertAndOrderDates(filteredItems.items, grpField)
    } else {
      filteredItems.items.sort(this.utils.sortByAttribute(grpField))
    }

    const groupKeyFunc =
      this.datasourceConfig.datasourceCustomGroupKeyFunc(config.dataSourceId) ??
      this.dateUtils.getDatasourceProviderGroupKeyFunc(
        config.argumentFieldIsDate,
        config.dateAxisTypeId
      )

    const jobIdField = this.datasourceConfig.getJobIdField(config.dataSourceId)

    const isCustomValueField =
      config.valueField != null && config.valueField !== DEFAULT_VALUE_FIELD

    const formatDateFunc = this.dateUtils.formatDatePadded

    const [splittingByDateField, isMultipleSeries, chartSeries] = this.utils.createChartSeries(
      filteredItems.items,
      config,
      formatDateFunc
    )

    let grouped = filteredItems.items.reduce((previous: GenericChartItem[], current) => {
      return this.reduceItem(
        previous,
        current,
        groupKeyFunc,
        filteredItems.filteredIds,
        isCustomValueField,
        config,
        jobIdField,
        splittingByDateField,
        chartSeries,
        formatDateFunc
      )
    }, [])

    grouped.forEach((g) => {
      g.jobIds.sort()
    })

    if (config.argumentFieldIsDate)
      grouped = this.fillInDatePeriodsWithZeroValues(grouped, config, chartSeries)

    if (isCustomValueField && config.valueAggregationTypeId === ValueAggregationType.Average) {
      grouped.forEach((g) => {
        if (isMultipleSeries) {
          chartSeries.forEach((series) => {
            g[series.value] = this.utils.round(
              g[series.value] / g.seriesNumItems[series.value],
              AVERAGING_DECIMAL_PLACES_ROUNDING
            )
          })
        }
        g.value =
          g.numItems === 0
            ? 0
            : this.utils.round(g.value / g.numItems, AVERAGING_DECIMAL_PLACES_ROUNDING)
      })
    } else if (config.valueAggregationTypeId === ValueAggregationType.Cumulative) {
      let cumulativeSum = config.startingValue && !isMultipleSeries ? config.startingValue : 0
      let cumulativeNumItems = 0
      const multipleSeriesCumulativeSums: { [id: string]: number } = {}
      chartSeries.forEach((s) => (multipleSeriesCumulativeSums[s.value] = 0))

      grouped = grouped.map((g) => {
        if (isMultipleSeries) {
          chartSeries.forEach((series) => {
            if (!g[series.value]) g[series.value] = 0
            g[series.value] += multipleSeriesCumulativeSums[series.value]
            multipleSeriesCumulativeSums[series.value] = g[series.value]
          })
        }

        cumulativeNumItems += g.numItems
        g.hideNumItems = true
        g['additionalPointInfoValues'] = [
          { label: 'Cumulative Num Items', value: cumulativeNumItems },
          {
            label: 'Num Items for current grouping',
            value: g.numItems,
            isNumber: true,
            notBold: true,
          },
          { label: 'Value for current grouping', value: g.value, isNumber: true, notBold: true },
        ]

        g.value += cumulativeSum
        cumulativeSum = g.value

        return g
      })
    }

    console.debug(config.cardTitle + ' grouped: ')
    console.debug(grouped)

    return { data: grouped, splitSeries: isMultipleSeries, dynamicSeries: chartSeries }
  }

  private reduceItem(
    previousItems: GenericChartItem[],
    currentItem: any,
    groupKeyFunc: GROUP_KEYS_FUNCTION,
    filteredIds: (number | string)[],
    isCustomValueField: boolean,
    config: ChartConfig,
    jobIdField: string,
    splittingByDateField: boolean,
    chartSeries: ChartValue[],
    formatDateFunc: (d: any) => string
  ): GenericChartItem[] {
    if (!currentItem[config.argumentField]) {
      return previousItems // group as 'no data' instead of ignoring? (if argument is not date)
    }

    const isFilteredOut = filteredIds.indexOf(currentItem.id) > -1
    // date groupings are already padded - see fillInDatePeriodsWithZeroValues()
    if (isFilteredOut && config.argumentFieldIsDate) return previousItems

    const groupKeys = groupKeyFunc(currentItem, config)

    if (!groupKeys.length) {
      console.error('groupKeys not array?')
    }

    for (let i = 0; i < groupKeys.length; i++) {
      const groupKey = groupKeys[i]

      let existingItem: GenericChartItem[]
      if (config.argumentFieldIsDate) {
        existingItem = previousItems.filter(
          (g) => g.groupField.getTime() === (groupKey as Date).getTime()
        )
      } else {
        existingItem = previousItems.filter((g) => g.groupField === groupKey)
      }

      let value = isCustomValueField ? currentItem[config.valueField] : 1
      if (isFilteredOut) value = 0

      let multipleSeriesName = this.utils.checkForNullOrEmpty(currentItem[config.splitByField])
      if (splittingByDateField) multipleSeriesName = formatDateFunc(multipleSeriesName)

      if (existingItem.length === 1) {
        const existingItemSingle = existingItem[0]
        existingItemSingle.value += value
        if (!isFilteredOut) existingItemSingle.numItems++

        if (!existingItemSingle[multipleSeriesName]) existingItemSingle[multipleSeriesName] = 0
        existingItemSingle[multipleSeriesName] += value

        if (!isFilteredOut) existingItemSingle.seriesNumItems[multipleSeriesName]++

        if (!isFilteredOut && !existingItemSingle.jobIds.includes(currentItem[jobIdField])) {
          existingItemSingle.jobIds.push(currentItem[jobIdField])
        }
      } else {
        const item = {
          groupField: groupKey,
          numItems: isFilteredOut ? 0 : 1,
          value,
          jobIds: isFilteredOut ? [] : [currentItem[jobIdField]],
        } as GenericChartItem

        this.initChartItemZeroValues(item, chartSeries)

        item[multipleSeriesName] = value
        item.seriesNumItems[multipleSeriesName] = isFilteredOut ? 0 : 1

        previousItems.push(item)
      }
    }

    return previousItems
  }

  private convertAndOrderDates(items: any[], dateField: string) {
    items.forEach((i) => {
      const dateString = i[dateField]
      if (dateString) {
        i[dateField] = new Date(i[dateField])
      }
    })

    items.sort(
      (a, b) =>
        (Object.prototype.hasOwnProperty.call(a, dateField) && a[dateField]
          ? a[dateField].getTime()
          : 0) -
        (Object.prototype.hasOwnProperty.call(b, dateField) && b[dateField]
          ? b[dateField].getTime()
          : 0)
    )
  }

  /** Fill 'missing' periods with 0 values */
  private fillInDatePeriodsWithZeroValues(
    grouped: GenericChartItem[],
    config: ChartConfig,
    chartSeries: ChartValue[]
  ): GenericChartItem[] {
    const periodName = this.dateUtils.getDayJsPeriodName(config.dateAxisTypeId)

    const filledInDates = grouped.reduce((newArray, currentModel, index, originalArray) => {
      const nextModel = originalArray[index + 1]

      if (nextModel) {
        const currentDate = dayjs(currentModel.groupField)
        const monthsBetween = dayjs(nextModel.groupField).diff(currentDate, periodName)

        const fillerDates = Array.from({ length: monthsBetween - 1 }, (value, dayIndex) => {
          const nextItem = {
            value: 0,
            groupField: dayjs(currentDate)
              .add(dayIndex + 1, periodName)
              .toDate(),
            numItems: 0,
          } as GenericChartItem

          this.initChartItemZeroValues(nextItem, chartSeries)

          return nextItem
        })

        newArray.push(currentModel, ...fillerDates)
      } else {
        newArray.push(currentModel)
      }

      return newArray
    }, [] as GenericChartItem[])

    return filledInDates
  }

  private initChartItemZeroValues(item: GenericChartItem, chartSeries: ChartValue[]) {
    const seriesNumItems = {} as any
    chartSeries.forEach((s) => (seriesNumItems[s.value] = 0))

    item['seriesNumItems'] = seriesNumItems

    chartSeries.forEach((s) => (item[s.value] = 0))
  }
}
