import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { ConfirmComponent } from './confirm.component'

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  constructor(private dialog: MatDialog) {}

  confirm(title: string, messageHTML: string, danger = false): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '400px',
      data: { title, message: messageHTML, danger },
    })

    return dialogRef.afterClosed()
  }

  confirmDelete(messageHTML: string): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '400px',
      data: {
        title: 'Confirm Delete',
        message: messageHTML,
        danger: true,
        yesText: 'Delete',
      },
    })

    return dialogRef.afterClosed()
  }
}
