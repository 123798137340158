export interface IncomeInvoiceDto {
  id: number
  jobId: number
  jobNumber: string
  incomeInvoiceTypeId: IncomeInvoiceType

  invoiceDate: Date
  dueDate: Date
  postedDate: Date

  totalExGST: number
  totalIncGST: number
  totalGST: number
  incomeInvoiceStatusId: IncomeInvoiceStatus
  division: string
  siteManager: string
}

export enum IncomeInvoiceType {
  Deposit = 1,
  Claim = 2,
  Variation = 3,
}

export enum IncomeInvoiceStatus {
  Created = 1,
  Sent = 2,
  Posted = 3,
}
