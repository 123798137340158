import { ChartField } from 'src/app/types/chart.model'
import {
  JOBNUM_FIELD,
  JOBNUM_LABEL,
  NUM_MATCHED_ITEMS_VALUE_FIELD,
} from 'src/app/types/constants'

// processed = status converted to string
export interface ProcessedJobTask {
  id: number

  jobId: number
  jobNumber: string

  startDate: Date
  endDate?: Date

  status: string
  allocatedTo: string
  division: string
  vendor: string
  salesRep: string
  siteManager: string
}

export const JOBTASK_ALL_FIELDS: ChartField[] = [
  {
    dataField: JOBNUM_FIELD,
    displayName: JOBNUM_LABEL,
    dataType: 'string',
  },
  {
    dataField: 'status',
    displayName: 'Status',
    dataType: 'string',
  },
  {
    dataField: 'allocatedTo',
    displayName: 'Allocated To',
    dataType: 'string',
  },
  {
    dataField: 'division',
    displayName: 'Division',
    dataType: 'string',
  },
  {
    dataField: 'salesRep',
    displayName: 'Sales Rep',
    dataType: 'string',
  },
  {
    dataField: 'siteManager',
    displayName: 'Site Manager',
    dataType: 'string',
  },
  {
    dataField: 'vendor',
    displayName: 'Vendor',
    dataType: 'string',
  },
  {
    dataField: 'startDate',
    displayName: 'Start Date',
    dataType: 'date',
  },
  {
    dataField: 'endDate',
    displayName: 'End Date',
    dataType: 'date',
  },
]

export const JOBTASK_FILTER_FIELDS: ChartField[] = JOBTASK_ALL_FIELDS.filter(
  (jtf) =>
    jtf.dataType === 'string' ||
    jtf.dataType === 'boolean' ||
    jtf.dataType === 'date' ||
    jtf.dataType === 'number'
)

export const JOBTASK_DATE_FILTER_FIELDS: ChartField[] = JOBTASK_ALL_FIELDS.filter(
  (jtf) => jtf.dataType === 'date'
)

export const JOBTASK_ARGUMENT_FIELDS: ChartField[] = JOBTASK_ALL_FIELDS.filter(
  (jtf) =>
    jtf.dataType === 'string' || jtf.dataType === 'date' || jtf.dataType === 'number'
)

export const JOBTASK_VALUE_FIELDS: ChartField[] = [NUM_MATCHED_ITEMS_VALUE_FIELD]

export const JOBTASK_SERIES_GROUP_FIELDS: ChartField[] = JOBTASK_ALL_FIELDS.filter(
  (jtf) => jtf.dataType === 'string' || jtf.dataType === 'boolean'
)
