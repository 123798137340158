import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { HttpErrorResponse } from '@angular/common/http'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastr: ToastrService) {}

  // see following for options https://www.npmjs.com/package/ngx-toastr

  showSuccess(
    message: string,
    title: string | undefined = undefined,
    options: any = { timeOut: 3000 }
  ) {
    this.toastr.success(message, title, options)
  }

  showError(
    message: string,
    title: string | undefined = undefined,
    options: any = { timeOut: 0 }
  ) {
    this.toastr.error(message, title, options)
  }

  showInfo(
    message: string,
    title: string | undefined = undefined,
    options = { timeOut: 5000 }
  ) {
    this.toastr.info(message, title, options)
  }

  showWarning(
    message: string,
    title: string | undefined = undefined,
    options: any = { timeOut: 5000 }
  ) {
    this.toastr.warning(message, title, options)
  }

  handleError(error: HttpErrorResponse, options: any = { timeOut: 0 }) {
    let msg
    let title
    if (error.statusText !== 'handled') {
      if (error.status === 422) {
        if (error.error && error.error.message) {
          if (error.error.message.indexOf('not found') > 0) {
            title = error.error.message
            msg = 'Another user may have deleted the record. Please refresh the page.'
          } else {
            msg = error.error.message
          }
        } else {
          msg = 'There was an unexpected error. Please try again in a moment.'
        }
        this.toastr.warning(msg, title, options)
      } else if (error.status === 403) {
        title = 'You do not have permissions for this action'
        msg = 'Please consult your supervisor if this is unexpected'
        this.toastr.warning(msg, title, options)
      } else {
        title = 'There was an unexpected error'
        msg = 'Please try again in a moment.'
        this.toastr.error(msg, title, options)
      }
      console.error(error)
    }
  }
}
