import { EventEmitter, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError, takeUntil, tap } from 'rxjs/operators'
import { ScreenService } from 'src/app/shared/services'
import { DashboardApiService } from 'src/app/shared/services/api/dashboard.api.service'
import { DatasourceConfigService } from 'src/app/shared/services/datasource-config.service'
import { LogService } from 'src/app/shared/services/log.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { UtilsService } from 'src/app/shared/services/utils.service'
import { ChartConfig } from 'src/app/types/chart.model'
import { AppMatCard } from 'src/app/types/dashboard.model'
import { DashboardDto, DashCardDto } from 'src/app/types/dto/dashboard.types.dto'
import { DashCardRequest, PatchDashboardRequest } from 'src/app/types/request/dashboard.req'
import { CardSize } from 'src/app/types/enums'

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  cols = 1
  displayCards: AppMatCard[] = []
  dashboard: DashboardDto | undefined
  NEW_CARD_TITLE = 'New Chart'

  addChartCols = 1 // want add chart btn to be centered in remaining space on last row
  dashboardSelected = new EventEmitter<boolean>()
  private selectingDashboard = new EventEmitter<null>()

  constructor(
    private datasourceConfigService: DatasourceConfigService,
    private dashboardApi: DashboardApiService,
    private screen: ScreenService,
    private logger: LogService,
    private notiService: NotificationService,
    private utils: UtilsService
  ) {
    this.screen.changed.subscribe(() => this.processChange())
  }

  initDashboard(dashBoardId: number | undefined) {
    this.selectingDashboard.emit()

    if (dashBoardId) {
      this.getDashboard(dashBoardId)
    } else {
      this.displayCards = []
      this.dashboard = undefined
    }
  }

  refresh() {
    if (!this.dashboard) {
      this.logger.log('dashboard.service', 'refresh', null, 'no dashboard')
      throw Error('no dashboard in refresh dashboard')
    }

    this.getDashboard(this.dashboard.id)
  }

  updateCard(card: DashCardDto) {
    if (this.dashboard) {
      const toUpdate = this.dashboard.cards.filter((d) => d.id == card.id)[0]
      const indexToDelete = this.dashboard.cards.indexOf(toUpdate)
      this.dashboard.cards.splice(indexToDelete, 1, card)
      this.processChange()
    }
  }

  initLoadedDashboard(board: DashboardDto) {
    this.dashboard = board
    this.processChange()
  }

  getChartConfig(cardId: number): ChartConfig {
    const card = this.displayCards.filter((c) => c.id === cardId)[0]

    if (
      card.chartTypeId == null ||
      card.dataSourceId == null ||
      card.dateAxisTypeId == null
    ) {
      this.logger.log(
        'dashboard.service',
        'getChartConfig',
        null,
        'Chart not initialised'
      )
      throw new Error('Chart not initialised')
    }

    const isDateArg = this.datasourceConfigService.isDateArgument(
      card.dataSourceId,
      card.argumentField
    )

    return {
      cardTitle: card.title,

      dataSourceId: card.dataSourceId,

      dateRangeTypeId: card.dateRangeTypeId,
      dateFilterField: card.dateFilterField,
      dateFrom: card.dateFrom,
      dateTo: card.dateTo,

      argumentField: card.argumentField,
      splitByField: card.splitByField,
      dateAxisTypeId: card.dateAxisTypeId,
      argumentFieldIsDate: isDateArg,
      valueField: card.valueField,
      valueAggregationTypeId: card.valueAggregationTypeId,
      startingValue: card.startingValue,
      showFilteredCategoriesOnly: card.showFilteredCategoriesOnly,

      chartTypeId: card.chartTypeId,
      filters: card.filters,
      chartColour: card.chartColour,
      targetValue: card.targetValue,

      taskMasterId: card.taskMasterId,
      taskMaster2Id: card.taskMaster2Id,
      task1DateField: card.task1DateField,
      task2DateField: card.task2DateField,
      taskDurationAggregation: card.taskDurationAggregation,

      expectedPercentCancellation: card.expectedPercentCancellation,
    } as ChartConfig
  }

  processChange() {
    if (this.dashboard) {
      if (this.screen.isSmallOrLess) {
        this.cols = 1
        this.displayCards = this.processSmallScreenCards(this.dashboard.cards)
      } else if (this.screen.isXLarge) {
        this.cols = 4
        this.displayCards = this.processLargeScreenCards(this.dashboard.cards)
      } else {
        this.cols = 2
        this.displayCards = this.processMedScreenCards(this.dashboard.cards)
      }
    }

    this.calcAddChartCols()
  }

  setTitle(newName: string) {
    if (this.dashboard) {
      this.dashboard.name = newName
      this.processChange()
    }
  }

  calcAddChartCols() {
    let finalRowCols = 0
    this.displayCards.forEach((c) => {
      finalRowCols += c.cols
      if (finalRowCols > this.cols) finalRowCols = c.cols
      if (finalRowCols === this.cols) finalRowCols = 0
    })

    this.addChartCols = this.cols - finalRowCols
  }

  makeCardLarger(card: AppMatCard) {
    if (this.dashboard) {
      const req = this.patchReqFromDashboard()
      const cardMatch = req.cards.filter((c) => c.id === card.id)[0]
      switch (card.size) {
        case CardSize.medium:
          cardMatch.size = CardSize.large
          break
        case CardSize.small:
          cardMatch.size = CardSize.medium
          break
      }
      this.saveChanges(req).subscribe()
    }
  }

  makeCardSmaller(card: AppMatCard) {
    if (this.dashboard) {
      const req = this.patchReqFromDashboard()
      const cardMatch = req.cards.filter((c) => c.id === card.id)[0]
      switch (card.size) {
        case CardSize.medium:
          cardMatch.size = CardSize.small
          break
        case CardSize.large:
          cardMatch.size = CardSize.medium
          break
      }
      this.saveChanges(req).subscribe()
    }
  }

  moveCard(card: AppMatCard, moveLeft = false) {
    if (this.dashboard) {
      const req = this.patchReqFromDashboard()
      const currentIndex = req.cards.findIndex((c) => c.id === card.id)
      const newIndex = moveLeft ? currentIndex - 1 : currentIndex + 1
      this.utils.arrayMove(req.cards, currentIndex, newIndex)

      this.saveChanges(req).subscribe()
    }
  }

  addCard() {
    const req = this.patchReqFromDashboard()
    req.cards.push({
      size: CardSize.medium,
      title: this.NEW_CARD_TITLE,
    })

    this.saveChanges(req).subscribe()
  }

  addCardRight(card: AppMatCard) {
    const req = this.patchReqFromDashboard()
    const matchIndex = req.cards.findIndex((c) => c.id === card.id)
    req.cards.splice(matchIndex + 1, 0, {
      size: CardSize.medium,
      title: this.NEW_CARD_TITLE,
    })

    this.saveChanges(req).subscribe()
  }

  removeCard(card: AppMatCard): Observable<DashboardDto> {
    const req = this.patchReqFromDashboard()
    const matchIndex = req.cards.findIndex((c) => c.id === card.id)
    req.cards.splice(matchIndex, 1)
    return this.saveChanges(req)
  }

  isLastCard(index: number): boolean {
    return index == this.displayCards.length - 1
  }

  // currently saving on every change
  saveChanges(req: PatchDashboardRequest): Observable<DashboardDto> {
    if (this.dashboard) {
      return this.dashboardApi.editDashboard(this.dashboard.id, req).pipe(
        tap((res) => {
          this.dashboard = res
          this.processChange()
          this.notiService.showSuccess('Dashboard updated')
        }),
        catchError((err) => {
          this.notiService.handleError(err)
          this.logger.log('dashboard.service', 'saveChanges', err)
          throw err
        })
      )
    }
    throw new Error('no dash in saveChanges')
  }

  private getDashboard(dashBoardId: number) {
    this.dashboardApi
      .getDashboard(dashBoardId)
      .pipe(takeUntil(this.selectingDashboard))
      .subscribe({
        next: (board) => {
          this.dashboard = board
          this.processChange()
          this.dashboardSelected.emit(true)
          this.calcAddChartCols()
        },
        error: (err) => {
          this.dashboardSelected.emit(false)
          this.logger.log('dashboard.service', 'initDashboard', err)
        },
      })
  }

  private patchReqFromDashboard(): PatchDashboardRequest {
    if (!this.dashboard) {
      this.logger.log(
        'dashboard.service',
        'saveChanges',
        null,
        'no dashboard in patchReqFromDashboard'
      )
      throw new Error('no dashboard in patchReqFromDashboard')
    }

    const cardsReq: DashCardRequest[] = []
    this.dashboard.cards.forEach((c) =>
      cardsReq.push({ id: c.id, size: c.size, title: c.title })
    )
    return { name: this.dashboard.name, cards: cardsReq }
  }

  private processSmallScreenCards(cards: DashCardDto[]): AppMatCard[] {
    const processedCards: AppMatCard[] = []
    cards.forEach((c) => {
      processedCards.push(new AppMatCard(c, 1, 1))
    })

    return processedCards
  }

  private processMedScreenCards(cards: DashCardDto[]): AppMatCard[] {
    const processedCards: AppMatCard[] = []
    cards.forEach((c) => {
      const cols = c.size === CardSize.large ? 2 : 1
      processedCards.push(new AppMatCard(c, cols, 1))
    })

    return processedCards
  }

  private processLargeScreenCards(cards: DashCardDto[]): AppMatCard[] {
    const processedCards: AppMatCard[] = []
    cards.forEach((c) => {
      const cols = c.size === CardSize.small ? 1 : c.size === CardSize.medium ? 2 : 4
      processedCards.push(new AppMatCard(c, cols, 1))
    })

    return processedCards
  }
}
