import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHelperService } from '../../shared/services/http.service';
import { environment } from '../../../environments/environment';
import { TrainingTopicDto } from '../../types/dto/training-topic.dto';
import { Observable, forkJoin, throwError } from 'rxjs';
import { TrainingCategoryDto } from '../../types/dto/training-category';
import { TrainingCourseDto } from '../../types/dto/training-course';
import { TrainingLevelDto } from '../../types/dto/training-level';
import { TrainingDocumentDto } from '../../types/dto/training-document';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  appBaseURL = environment.apiUrl + 'training/';
  topicsURL = environment.apiUrl + 'training-topics/';
  categoriesURL = environment.apiUrl + 'training-categories/';
  coursesURL = environment.apiUrl + 'training-courses/';
  levelsURL = environment.apiUrl + 'training-levels/';
  documentsURL = environment.apiUrl + 'training-documents/';
  trainingTopics: TrainingTopicDto[] = [];
  trainingCategories: TrainingCategoryDto[] = [];
  trainingCourses: TrainingCourseDto[] = [];
  trainingLevels: TrainingLevelDto[] = [];

  constructor(
    private http: HttpClient,
    private httpHelper: HttpHelperService) { }

  getTrainingTopics(): Observable<TrainingTopicDto[]> {
    return this.http.get<TrainingTopicDto[]>(
      this.topicsURL,
      this.httpHelper.httpOptions).pipe(
        tap(res => {
          this.trainingTopics = res;
        }),
        catchError(this.handleError));
  }

  addTrainingTopic(dataRecord: any): Observable<TrainingTopicDto> {
    return this.http.post<TrainingTopicDto>(this.topicsURL, JSON.stringify(dataRecord), this.httpHelper.httpOptions);
  }

  updateTrainingTopic(id: string, itm: any): Observable<TrainingTopicDto> {
    const url = this.topicsURL + id;
    return this.http.patch<TrainingTopicDto>(url, JSON.stringify(itm), this.httpHelper.httpOptions);
  }

  deleteTrainingTopic(id: string) {
    const url = this.topicsURL + id;
    return this.http.delete(url, this.httpHelper.httpOptions);
  }



  getTrainingCategories(): Observable<TrainingCategoryDto[]> {
    return this.http.get<TrainingCategoryDto[]>(
      this.categoriesURL,
      this.httpHelper.httpOptions).pipe(
        tap(res => {
          this.trainingCategories = res;
        }),
        catchError(this.handleError));
  }

  addTrainingCategory(dataRecord: any): Observable<TrainingCategoryDto> {
    return this.http.post<TrainingCategoryDto>(this.categoriesURL, JSON.stringify(dataRecord), this.httpHelper.httpOptions);
  }

  updateTrainingCategory(id: string, itm: any): Observable<TrainingCategoryDto> {
    const url = this.categoriesURL + id;
    return this.http.patch<TrainingCategoryDto>(url, JSON.stringify(itm), this.httpHelper.httpOptions);
  }

  deleteTrainingCategory(id: string) {
    const url = this.categoriesURL + id;
    return this.http.delete(url, this.httpHelper.httpOptions);
  }



  getTrainingCourses(): Observable<TrainingCourseDto[]> {
    return this.http.get<TrainingCourseDto[]>(
      this.coursesURL,
      this.httpHelper.httpOptions).pipe(
        tap(res => {
          this.trainingCourses = res;
        }),
        catchError(this.handleError));
  }

  addTrainingCourse(dataRecord: any): Observable<TrainingCourseDto> {
    return this.http.post<TrainingCourseDto>(this.coursesURL, JSON.stringify(dataRecord), this.httpHelper.httpOptions);
  }

  updateTrainingCourse(id: string, itm: any): Observable<TrainingCourseDto> {
    const url = this.coursesURL + id;
    return this.http.patch<TrainingCourseDto>(url, JSON.stringify(itm), this.httpHelper.httpOptions);
  }

  deleteTrainingCourse(id: string) {
    const url = this.coursesURL + id;
    return this.http.delete(url, this.httpHelper.httpOptions);
  }



  getTrainingLevels(): Observable<TrainingLevelDto[]> {
    return this.http.get<TrainingLevelDto[]>(
      this.levelsURL,
      this.httpHelper.httpOptions).pipe(
        tap(res => {
          this.trainingLevels = res;
        }),
        catchError(this.handleError));
  }

  addTrainingLevel(dataRecord: any): Observable<TrainingLevelDto> {
    return this.http.post<TrainingLevelDto>(this.levelsURL, JSON.stringify(dataRecord), this.httpHelper.httpOptions);
  }

  updateTrainingLevel(id: string, itm: any): Observable<TrainingLevelDto> {
    const url = this.levelsURL + id;
    return this.http.patch<TrainingLevelDto>(url, JSON.stringify(itm), this.httpHelper.httpOptions);
  }

  deleteTrainingLevel(id: string) {
    const url = this.levelsURL + id;
    return this.http.delete(url, this.httpHelper.httpOptions);
  }



  getTrainingDocuments(): Observable<TrainingDocumentDto[]> {
    return this.http.get<TrainingDocumentDto[]>(
      this.documentsURL,
      this.httpHelper.httpOptions);
  }

  addTrainingDocument(dataRecord: any): Observable<TrainingDocumentDto> {
    return this.http.post<TrainingDocumentDto>(this.documentsURL, JSON.stringify(dataRecord), this.httpHelper.httpOptions);
  }

  updateTrainingDocument(id: string, itm: any): Observable<TrainingDocumentDto> {
    const url = this.documentsURL + id;
    return this.http.patch<TrainingDocumentDto>(url, JSON.stringify(itm), this.httpHelper.httpOptions);
  }

  deleteTrainingDocument(id: string) {
    const url = this.documentsURL + id;
    return this.http.delete(url, this.httpHelper.httpOptions);
  }


  getTrainingData(): Observable<TrainingCategoryDto[]> {
    return forkJoin(
      [
        this.getTrainingCategories(),
        this.getTrainingLevels(),
        this.getTrainingTopics(),
        this.getTrainingCourses()
      ]
    )
      .pipe(map(
        ([data]) => {
          return data;
        }, (err: any) => {
          return this.returnError(err);
        }
      ));
  }


  returnError(err: any): string {
    console.log(JSON.stringify(err));
    if (err && err.error && err.error.message) {
      return JSON.stringify(err.error.message);
    } else if (err && err.error && err.error.detail) {
      return JSON.stringify(err.error.detail);
    } else if (err && err.error && err.error.Description && err.error.Description.length) {
      return JSON.stringify(err.error.Description[0]);
    } else {
      return JSON.stringify(err);
    }
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return throwError(err);
  }
}
