import { EventEmitter, Injectable } from '@angular/core';
import { CompanyDto } from 'src/app/types/dto/company.dto';
import { ALL_STATE_KEYS, APP_AREA_KEY, COMPANY_ID_KEY } from 'src/app/types/session-state';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

export enum AppArea {
  dashboard = 'dashboard',
  home = 'home'
}

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  appVersion= environment.fullVersion;

  private areaSelected = '';
  areaChanged = new EventEmitter<string>();

  constructor(private auth: AuthService) {
    this.auth.currentUser.subscribe(user => {
      if (!user) {
        this.clearAppState();
      }
    });

    this.auth.currentCompany.subscribe(comp => {
      if (comp) {
        this.setCompany(comp);
      }
    });
  }

  setCompany(company: CompanyDto) {
    localStorage.setItem(COMPANY_ID_KEY, company.id.toString());
  }

  get currentCompanyId(): number | undefined {
    const idString = localStorage.getItem(COMPANY_ID_KEY);
    if (idString) {
      return Number.parseInt(idString);
    }
    return undefined;
  }

  setAreaSelected(area: AppArea) {
    this.areaSelected = area;
    localStorage.setItem(APP_AREA_KEY, area);
    this.areaChanged.emit(this.areaSelected);
  }

  restoreAreaSelectedfromCache() {
    const areaStr = localStorage.getItem(APP_AREA_KEY);
    if (areaStr) {
      this.areaSelected = areaStr;
    }
  }

  private clearAppState() {
    ALL_STATE_KEYS.forEach(k => sessionStorage.removeItem(k));
  }
}
