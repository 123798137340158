import { Injectable } from '@angular/core'
import { ChartConfig, GenericChartItem } from 'src/app/types/chart.model'
import { ProcessedJobTask } from 'src/app/types/datasources/tasks.model'
import { DateUtilsService } from './date-utils.service'
import { UtilsService } from './utils.service'

import dayjs from 'dayjs'
import dayjsBusinessDays from 'dayjs-business-days2'
import { LogService } from './log.service'
import { ChartResult } from './chart-datasource-provider.service'
import { TaskDurationAggregation } from 'src/app/types/enums'
dayjs.extend(dayjsBusinessDays)

export interface DurationResult {
  task2JobNumsWithMatchingTask1: string[]
  sumOfDurationDays: number
  sumOfDurationWeeks: number
  sumOfDurationBusinessDays: number
}

@Injectable({
  providedIn: 'root',
})
export class TaskDurationService {
  constructor(
    private dateUtils: DateUtilsService,
    private logger: LogService,
    private utils: UtilsService
  ) {}

  processTaskDurations(
    tasks1: ProcessedJobTask[],
    tasks2: ProcessedJobTask[],
    task1Name: string,
    task2Name: string,
    config: ChartConfig
  ): ChartResult {
    if (!config.task1DateField || !config.task2DateField) {
      this.logger.log('task-duration', 'processTaskDurations', null, 'no task date fields')
      throw Error('no task date fields when fetching task duration source')
    }

    console.debug('Task duration processing for >> ' + config.cardTitle)
    console.debug('1st tasks:')
    console.debug(tasks1)
    console.debug('2nd tasks:')
    console.debug(tasks2)

    tasks2 = this.removeJobsWithAnyUnfinishedTasks(tasks2, config.task2DateField)

    const debug = tasks2

    tasks2 = this.removeAllButLastOfEachTaskForJob(tasks2, config.task2DateField)

    console.debug('removed non-last tasks:')
    console.debug(debug.filter((t) => tasks2.map((t2) => t2.id).indexOf(t.id) < 0))

    const groupKeyFunc = this.dateUtils.getSimpleGroupKeyFunc(
      config.argumentFieldIsDate,
      config.dateAxisTypeId
    )
    const groupedTask2 = this.utils.groupBy(tasks2, config.argumentField, groupKeyFunc)
    console.debug(groupedTask2)

    // foreach date period, foreach job in task2 list
    // calc duration in days for task1 min date -> task 2 max date
    // and get average over jobs foreach date period
    const chartItems: GenericChartItem[] = []
    const groupings = Object.keys(groupedTask2)
    groupings.forEach((grouping) => {
      console.debug('GROUPING: ' + grouping)
      const task2sForCurrentPeriod: ProcessedJobTask[] = groupedTask2[grouping as any]
      const result = {
        task2JobNumsWithMatchingTask1: [],
        sumOfDurationDays: 0,
        sumOfDurationWeeks: 0,
        sumOfDurationBusinessDays: 0,
      } as DurationResult

      task2sForCurrentPeriod.forEach((currTask2Job) => {
        this.getDurationsForJob(
          tasks1,
          task2sForCurrentPeriod,
          currTask2Job.jobId,
          currTask2Job.jobNumber,
          config.task1DateField!,
          config.task2DateField!,
          result
        )
      })

      const item = this.assembleChartItem(
        result,
        config,
        task1Name,
        task2Name,
        grouping,
        task2sForCurrentPeriod.length
      )

      chartItems.push(item)
    })

    return {
      data: chartItems,
      splitSeries: false,
    }
  }

  private assembleChartItem(
    result: DurationResult,
    config: ChartConfig,
    task1Name: string,
    task2Name: string,
    grouping: string,
    numItems: number
  ) {
    const numJobsAveraged = result.task2JobNumsWithMatchingTask1.length

    let averageDurationDays = 0
    let averageDurationBusinessDays = 0
    let averageDurationWeeks = 0
    if (numJobsAveraged) {
      averageDurationDays = Math.ceil(result.sumOfDurationDays / numJobsAveraged)
      averageDurationBusinessDays = Math.ceil(result.sumOfDurationBusinessDays / numJobsAveraged)
      averageDurationWeeks = this.utils.round(result.sumOfDurationWeeks / numJobsAveraged, 1)
    }

    let value: number
    let valueAxisString = ''
    switch (config.taskDurationAggregation) {
      case TaskDurationAggregation.Day:
        value = averageDurationDays
        valueAxisString = 'Av. Days'
        break
      case TaskDurationAggregation.BusinessDay:
        value = averageDurationBusinessDays
        valueAxisString = 'Av. Business Days'
        break
      case TaskDurationAggregation.Week:
        value = averageDurationWeeks
        valueAxisString = 'Av. Weeks'
        break
      default:
        value = averageDurationWeeks
        config.taskDurationAggregation = TaskDurationAggregation.Week
    }

    config.hideDefaultValueTooltipLines = true
    config.valueAxisTitle = valueAxisString
    config.tooltipTitle =
      `Average duration between <i>${config.task1DateField}</i>` +
      ` of <b>${task1Name}</b> and <i>${config.task2DateField}</i> of <b>${task2Name}</b>`

    return {
      groupField: config.argumentFieldIsDate ? new Date(grouping) : grouping,
      numItems: numItems,
      value: value,
      additionalPointInfoValues: [
        { label: 'Average duration WEEKS', value: averageDurationWeeks.toString() },
        { label: 'Average duration DAYS', value: averageDurationDays.toString() },
        {
          label: 'Average duration WORKING DAYS',
          value: averageDurationBusinessDays.toString(),
        },
        {
          label: 'Num jobs averaged for this period',
          value: numJobsAveraged.toString(),
        },
      ],
      jobIds: result.task2JobNumsWithMatchingTask1,
    } as GenericChartItem
  }

  /** updates 'result' param */
  private getDurationsForJob(
    tasks1: ProcessedJobTask[],
    tasks2: ProcessedJobTask[],
    jobId: number,
    jobNumber: string,
    task1Key: string,
    task2Key: string,
    result: DurationResult
  ) {
    const task1SameJob = tasks1
      .filter((t) => t.jobId === jobId)
      .sort(this.utils.sortByAttribute(task1Key))

    let task1MinDate = undefined
    if (task1SameJob.length > 0) {
      task1MinDate = task1SameJob[0][task1Key as keyof ProcessedJobTask]
    }

    const currJobsTask2 = tasks2
      .filter((j) => j.jobId === jobId)
      .sort(this.utils.sortByAttribute(task2Key, false))
    let task2MaxDate = undefined
    if (currJobsTask2.length > 0) {
      task2MaxDate = currJobsTask2[0][task2Key as keyof ProcessedJobTask]
    }

    let daysDuration = 0
    let businessDaysDuration = 0
    if (task1MinDate && task2MaxDate) {
      daysDuration = dayjs(task2MaxDate).diff(dayjs(task1MinDate), 'days')
      businessDaysDuration = dayjs(task2MaxDate).businessDiff(dayjs(task1MinDate))
      result.sumOfDurationDays += daysDuration
      result.sumOfDurationBusinessDays += businessDaysDuration
      result.sumOfDurationWeeks = result.sumOfDurationDays / 7
      result.task2JobNumsWithMatchingTask1.push(jobNumber)
      console.debug('  job id ' + jobId)
      console.debug('  task 1 min date ' + task1MinDate)
      console.debug('  task 2 max date ' + task2MaxDate)
      console.debug('  duration ' + daysDuration)
      console.debug('  business duration ' + businessDaysDuration)
    }
  }

  private removeAllButLastOfEachTaskForJob(tasks: ProcessedJobTask[], key: string) {
    return tasks.reduce((previousItems: ProcessedJobTask[], current) => {
      if (!current[key as keyof ProcessedJobTask]) {
        this.logger.log(
          'task-duration',
          'removeAllButLastOfEachTaskForJob',
          null,
          'null grouping field'
        )
        throw Error('null grouping field in processTaskDurations')
      }

      const existingKeyItem = previousItems.filter((g) => g.jobId === current.jobId)

      if (existingKeyItem.length === 1) {
        // already have task for job
        const existingDate = existingKeyItem[0][key as keyof ProcessedJobTask]
        const currDate = current[key as keyof ProcessedJobTask]
        if (existingDate && currDate && existingDate < currDate) {
          existingKeyItem[0] = current
          previousItems = previousItems
            .filter((g) => g.jobId !== current.jobId)
            .concat(existingKeyItem)
        }
      } else {
        previousItems.push(current)
      }

      return previousItems
    }, [])
  }

  private removeJobsWithAnyUnfinishedTasks(tasks: ProcessedJobTask[], key: string) {
    const jobsWithUnfinishedTasks = tasks
      .filter((t) => !t[key as keyof ProcessedJobTask])
      .map((t) => t.jobId)

    console.debug(`removing following jobs with ANY unfinished tasks for key ${key}:`)
    console.debug(jobsWithUnfinishedTasks.sort())

    return tasks.filter((t) => jobsWithUnfinishedTasks.indexOf(t.jobId) < 0)
  }
}
