import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { forkJoin, Observable, of } from 'rxjs'
import { DashboardApiService } from 'src/app/shared/services/api/dashboard.api.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import { PatchDashboardRequest } from 'src/app/types/request/dashboard.req'
import { DashboardDto } from 'src/app/types/dto/dashboard.types.dto'
import { DashboardService } from '../dashboard/dashboard.service'
import { DashboardsService } from '../dashboards.service'
import { CardSize } from 'src/app/types/enums'
import { LogService } from 'src/app/shared/services/log.service'
import { ConfirmService } from 'src/app/shared/components/confirm/confirm.service'

@Component({
  selector: 'app-add-edit-dashboard',
  templateUrl: './add-edit-dashboard.component.html',
  styleUrls: ['./add-edit-dashboard.component.scss'],
})
export class AddEditDashboardComponent implements OnInit {
  form: UntypedFormGroup | undefined
  isDefaultDashboard = false
  prevIsDefaultValue = false
  adding = false
  MAX_NAME_LEN = 25

  constructor(
    private formBuilder: UntypedFormBuilder,
    private logger: LogService,
    private notiService: NotificationService,
    private dashboardApi: DashboardApiService,
    private dashboardService: DashboardService,
    private dashboardsService: DashboardsService,
    private confirmService: ConfirmService,
    private dialogRef: MatDialogRef<AddEditDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.adding = data.adding
  }

  ngOnInit() {
    this.isDefaultDashboard =
      !this.adding &&
      this.dashboardsService.defaultDashboardId == this.dashboardService.dashboard?.id
    this.prevIsDefaultValue = this.isDefaultDashboard

    this.form = this.formBuilder.group({
      name: [
        this.adding ? '' : this.dashboardService.dashboard?.name,
        [Validators.maxLength(this.MAX_NAME_LEN), Validators.minLength(2)],
      ],
      isDefault: [],
    })
  }

  save() {
    if (this.form) {
      // start loading spinner
      this.form.disable()
      const newName = this.form.controls.name.value

      if (this.adding) {
        this.add(newName)
      } else {
        this.edit(newName)
      }
    }
  }

  private add(newName: string) {
    if (this.form) {
      const newDashReq = {
        name: newName,
        cards: [{ title: 'chart one', size: CardSize.medium }],
        setAsDefault: this.form.controls.isDefault.value ?? false,
      }

      this.dashboardApi.addDashboard(newDashReq).subscribe({
        next: (res) => {
          this.dashboardsService.addAndSelectDashboard(res)
          this.dashboardService.initLoadedDashboard(res)
          if (this.isDefaultDashboard) {
            this.dashboardsService.setDefaultDashboard(res.id)
          }

          this.notiService.showSuccess('Dashboard successfully added')
          this.dialogRef.close()
        },
        error: (err) => this.onError(err, 'add'),
      })
    }
  }

  private edit(newName: string) {
    if (this.dashboardService.dashboard && this.form) {
      const updatedDash = {
        name: newName,
        cards: this.dashboardService.dashboard.cards,
      } as PatchDashboardRequest

      const changedDefault = this.form.controls.isDefault.dirty

      const obsArray$: [Observable<DashboardDto>, Observable<object | null>] = [
        this.dashboardApi.editDashboard(this.dashboardService.dashboard.id, updatedDash),
        changedDefault
          ? this.dashboardApi.setDefaultDashboard(
              this.dashboardService.dashboard.id,
              this.isDefaultDashboard
            )
          : of(null),
      ]

      forkJoin(obsArray$).subscribe({
        next: ([updatedDash, _]: [DashboardDto, object | null]) => {
          if (this.dashboardService.dashboard) {
            this.dashboardService.setTitle(newName)

            this.dashboardsService.updateDashboardListChangedName(updatedDash.id, updatedDash.name)

            if (changedDefault) {
              this.dashboardsService.setDefaultDashboard(
                this.isDefaultDashboard ? this.dashboardService.dashboard.id : undefined
              )
            }

            this.notiService.showSuccess('Dashboard successfully updated')
            this.dialogRef.close()
          }
        },
        error: (err) => this.onError(err, 'edit'),
      })
    }
  }

  delete() {
    this.confirmService
      .confirmDelete(
        `Are you sure you want to DELETE the dashboard <b>${
          this.dashboardService.dashboard?.name ?? 'ERROR'
        }</b>? <br><br> This is irreversible!`
      )
      .subscribe((confirmed) => {
        if (!this.dashboardService.dashboard) {
          this.logger.log('add-edit-dashboard', 'delete', null, 'no dashboard')
          throw new Error('no dashboard in delete dashboard')
        }

        if (confirmed)
          this.dashboardApi.deleteDashboard(this.dashboardService.dashboard.id).subscribe({
            next: () => {
              if (this.dashboardService.dashboard) {
                this.dashboardsService.removeDashboard(this.dashboardService.dashboard.id)
                this.notiService.showSuccess('Dashboard successfully deleted')
                this.dialogRef.close()
              }
            },
            error: (err) => this.onError(err, 'delete'),
          })
      })
  }

  private onError(err: any, method: string) {
    // stop spinner
    this.form?.enable()
    this.notiService.handleError(err)
    this.logger.log('add-edit-dashboard', method, err)
  }
}
