import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

  @Input() inline = false;
  @Input() heightPx = '75'
  @Input() widthPx = '75'

  constructor() { }
}
