import {
  APP_ID,
  APP_NAME,
  B2C_AUTHORITY,
  B2C_AUTHORITY_DOMAIN,
  MAJOR_VERSION,
  PATCH_VERSION,
} from '../config/global-config'

export const environment = {
  production: true,
  local: false,
  applicationTitle: APP_NAME,
  clientName: APP_NAME,
  fullVersion: MAJOR_VERSION + PATCH_VERSION,
  majorVersion: MAJOR_VERSION,
  appId: APP_ID,

  applicationUrl: 'https://hub.truthengine.com.au/',
  apiUrl: 'https://api.truthengine.com.au/api/v1/',

  truthEngineAppUrl: 'https://core.truthengine.com.au/',
  trackingAppUrl: 'https://tracking.truthengine.com.au/',
  purchaseOrdersAppUrl: 'https://costing.truthengine.com.au/',
  estimatingAppUrl: 'https://estimating.truthengine.com.au/',
  configAppUrl: 'https://config.truthengine.com.au/',
  adminAppUrl: 'https://admin.truthengine.com.au/',

  authority: B2C_AUTHORITY,
  authorityDomain: B2C_AUTHORITY_DOMAIN,
  consentScopes: ['https://truthengine.onmicrosoft.com/FelixCoreApi/Access'],
  clientID: 'c1826393-c013-45c2-9282-9e7fdc3cf85e',
}
