import { ChartField } from '../chart.model'
import { JOBNUM_FIELD, JOBNUM_LABEL, NUM_MATCHED_ITEMS_VALUE_FIELD } from '../constants'

export const FORECAST_TASKS_FILTER_FIELDS: ChartField[] = [
  {
    dataField: JOBNUM_FIELD,
    displayName: JOBNUM_LABEL,
    dataType: 'string',
  },
  {
    dataField: 'siteManager',
    displayName: 'Site Manager',
    dataType: 'string',
  },
  {
    dataField: 'division',
    displayName: 'Division',
    dataType: 'string',
  },
  {
    dataField: 'houseType',
    displayName: 'House Type',
    dataType: 'string',
  },
]

export const FORECAST_TASKS_ARGUMENT_FIELDS: ChartField[] = [
  {
    dataField: 'forecastCompletionDate',
    displayName: 'Forecast Completion Date',
    dataType: 'date',
  },
  {
    dataField: 'forecastStartDate',
    displayName: 'Forecast Start Date',
    dataType: 'date',
  },
]

export const FORECAST_TASKS_VALUE_FIELDS: ChartField[] = [NUM_MATCHED_ITEMS_VALUE_FIELD]

export const FORECAST_TASKS_DATE_FILTER_FIELDS: ChartField[] = [
  {
    dataField: 'forecastCompletionDate',
    displayName: 'Forecast Completion Date',
    dataType: 'date',
  },
  {
    dataField: 'forecastStartDate',
    displayName: 'Forecast Start Date',
    dataType: 'date',
  },
]

export const FORECAST_TASKS_SERIES_GROUP_FIELDS: ChartField[] =
  FORECAST_TASKS_FILTER_FIELDS.filter(
    (jf) => jf.dataType === 'string' || jf.dataType === 'boolean'
  )
