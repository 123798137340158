import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddDashboardRequest, PatchDashboardRequest } from 'src/app/types/request/dashboard.req';
import { DashboardDto, DashboardsDto } from 'src/app/types/dto/dashboard.types.dto';
import { environment } from 'src/environments/environment';
import { HttpHelperService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardApiService {

  appBaseURL = environment.apiUrl + 'dash-boards/';

  constructor(
    private http: HttpClient,
    private httpHelper: HttpHelperService) { }

  getDashboards(): Observable<DashboardsDto> {
    return this.http.get<DashboardsDto>(
      this.appBaseURL,
      this.httpHelper.httpOptions);
  }

  getDashboard(dashBoardId: number): Observable<DashboardDto> {
    return this.http.get<DashboardDto>(
      this.appBaseURL + dashBoardId,
      this.httpHelper.httpOptions);
  }

  editDashboard(id: number, req: PatchDashboardRequest): Observable<DashboardDto> {
    return this.http.patch<DashboardDto>(
      this.appBaseURL + id,
      req,
      this.httpHelper.httpOptions);
  }

  addDashboard(req: AddDashboardRequest): Observable<DashboardDto> {
    return this.http.post<DashboardDto>(
      this.appBaseURL,
      req,
      this.httpHelper.httpOptions);
  }

  deleteDashboard(dashBoardId: number) {
    return this.http.delete(
      this.appBaseURL + dashBoardId,
      this.httpHelper.httpOptions);
  }

  setDefaultDashboard(dashBoardId: number, isDefault: boolean) {
    return this.http.patch(
      this.appBaseURL + dashBoardId + '/default',
      { isDefault: isDefault },
      this.httpHelper.httpOptions);
  }
}
