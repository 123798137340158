import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, Subscription } from 'rxjs'
import { mergeMap, map, catchError } from 'rxjs/operators'

import { NotificationService } from './notification.service'
import { AuthService } from './auth.service'

import { LogService } from './log.service'
import { AuthApiService } from './api/auth.api.service'
import { UserService } from './api/user.api.service'
import { UtilsService } from './utils.service'
import { AppStateService } from './app-state.service'

@Injectable({
  providedIn: 'root',
})
export class RefreshGuard {
  subscriptions: Subscription[] = []

  constructor(
    private router: Router,
    private utils: UtilsService,
    private appState: AppStateService,
    private auth: AuthService,
    private authApi: AuthApiService,
    private userService: UserService,
    private notiService: NotificationService,
    private logger: LogService
  ) {}

  canActivate() {
    if (!this.utils.isHttps()) {
      this.router.navigate([''])
    }

    //this.auth.currentVersion = environment.fullVersion;
    //this.checkCorrectVersion();

    if (this.auth.getUserEmail()) {
      return true
    } else {
      const companyId = this.appState.currentCompanyId
      const msalEmail = this.auth.getMsalUserEmail()
      if (companyId && msalEmail) {
        return this.restoreFromCache(companyId, msalEmail)
      } else {
        this.router.navigate([''])
        return false
      }
    }
  }

  restoreFromCache(compId: number, email: string): Observable<boolean> {
    return this.authApi.getAuthorisedCompanies(email).pipe(
      mergeMap((comps) => {
        const company = comps.filter((x) => x.id === compId)
        if (company.length !== 1) {
          this.error('no matching comp')
          throw Error()
        }
        this.auth.setCurrentCompany(company[0])
        // forkJoin this.compService.getCompanyConfigurations()
        return this.userService.getUser(company[0].userId)
      }),
      map((user) => {
        this.auth.setCurrentUser(user)
        this.appState.restoreAreaSelectedfromCache()
        //this.globalService.companyConfiguration = config;
        //this.globalService.compConfigChanged.next();
        return true
      }),
      catchError((err) => {
        this.error(err)
        throw err
      })
    )
  }

  // checkCorrectVersion() {
  //     this.authApi.getCurrentApplicationVersion().subscribe(appVersion => {
  //         if (appVersion.version.trim() !== environment.majorVersion) {
  //             this.auth.versionIncorrect = true;
  //             this.auth.correctVersion = appVersion.version.trim();
  //             console.log('version incorrect so routing to login');
  //         } else {
  //             this.auth.versionIncorrect = false;
  //         }
  //     },
  //     err => {
  //         this.logger.log('refresh-guard', '', err, 'couldn\'t get app version', true);
  //     });
  // }

  error(err: any) {
    this.router.navigate([''])
    this.logger.log('refreshGuard', '', err)
    this.notiService.showError(
      'There was an error fetching data from the session so you will have to login again'
    )
  }
}
