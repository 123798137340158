import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { DashboardJobDto } from 'src/app/types/dto/dashboard-job.dto'
import { ProcessedDashboardJob } from 'src/app/types/datasources/job.model'
import { environment } from 'src/environments/environment'
import { AuthService } from '../auth.service'
import { DatasourceConfigService } from '../datasource-config.service'
import { HttpHelperService } from '../http.service'
import { TimedCache } from '../timed-cache'
import { UtilsService } from '../utils.service'
import { ChartField } from 'src/app/types/chart.model'
import { JobDto, ProcessedJob } from 'src/app/types/dto/job.dto'
import { LogService } from '../log.service'

@Injectable({
  providedIn: 'root',
})
export class JobsApiService {
  private cachedJobs = new TimedCache<ProcessedDashboardJob[]>()

  constructor(
    private datasourceConfig: DatasourceConfigService,
    private httpHelper: HttpHelperService,
    private logger: LogService,
    private utils: UtilsService,
    private auth: AuthService,
    private http: HttpClient
  ) {}

  getJob(jobNumber: string): Observable<ProcessedJob> {
    const comp = this.auth.getCurrentCompany()
    if (!comp) {
      this.logger.log('jobs.api', 'getJob', null, 'no company')
      throw Error('no company set in getJob')
    }

    return this.http
      .get<JobDto>(environment.apiUrl + `jobs/${jobNumber}`, this.httpHelper.httpOptions)
      .pipe(
        map((j) => {
          const jobAddress = this.utils.generateJobAddressString(j.jobAddress)
          const contractAddress = this.utils.generateJobAddressString(j.contractAddress)
          return { ...j, contractAddress, jobAddress }
        })
      )
  }

  getJobs(): Observable<ProcessedDashboardJob[]> {
    const comp = this.auth.getCurrentCompany()
    if (!comp) {
      this.logger.log('jobs.api', 'getJobs', null, 'no company')
      throw Error('no company set in getJobs')
    }

    if (this.cachedJobs.getCacheItem(comp.id)) {
      return this.cachedJobs.getCacheItem(comp.id)!
    }

    const obs$ = this.http
      .get<DashboardJobDto[]>(
        environment.apiUrl + 'jobs/for-dashboard',
        this.httpHelper.httpOptions
      )
      .pipe(map((jobs) => this.flattenRoles(jobs)))

    return this.cachedJobs.setCacheItem(comp.id, obs$)
  }

  private flattenRoles(jobs: DashboardJobDto[]): ProcessedDashboardJob[] {
    let roles: ChartField[] = []
    jobs.forEach((job) =>
      job.jobRoles.forEach((role) =>
        roles.push({
          dataField: this.utils.toAttributeFriendlyName(role.companyRoleDescription),
          displayName: role.companyRoleDescription,
          dataType: 'string',
        } as ChartField)
      )
    )
    // get unique values
    roles = [...new Map(roles.map((role) => [role.dataField, role])).values()]

    const processedJobs: ProcessedDashboardJob[] = []
    jobs.forEach((job) => {
      const processed: Partial<ProcessedDashboardJob> = { ...job }
      roles.forEach((role) => {
        processed[role.dataField] = job.jobRoles.filter(
          (jr) => jr.companyRoleDescription === role.displayName
        )[0]?.name
      })
      processedJobs.push(processed as ProcessedDashboardJob)
    })

    this.datasourceConfig.adjustJobFieldsForRoles(Array.from(roles))

    return processedJobs
  }
}
