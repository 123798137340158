import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { DashboardService } from './dashboard.service'

@Component({
  templateUrl: 'dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  selector: 'app-dashboard',
})
export class DashboardComponent implements OnChanges, OnDestroy {
  @Input() dashboardId: number | undefined

  loading = true
  error = false
  private readonly destroying = new Subject<null>()

  constructor(public dashService: DashboardService) {
    this.dashService.dashboardSelected
      .pipe(takeUntil(this.destroying))
      .subscribe((success) => {
        this.loading = false
        if (!success) {
          this.error = true
        }
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dashboardId?.currentValue) {
      this.dashService.initDashboard(changes.dashboardId.currentValue)
    }
  }

  ngOnDestroy(): void {
    this.destroying.next(null)
    this.destroying.complete()
  }
}
