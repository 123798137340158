<ng-container>
  <app-side-nav-outer-toolbar>
    <!-- iframe guard to avoid reload during msal acquireTokenSilent()-->
    <router-outlet *ngIf="!isIframe"></router-outlet>
    <!-- <app-footer>
      All trademarks or registered trademarks are property of their respective owners.
    </app-footer> -->
  </app-side-nav-outer-toolbar>
</ng-container>

