import { Output, Injectable, EventEmitter } from '@angular/core'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'

//   xSmall: < 600px
//   small: 600 - 959
//   medium: 960 - 1279
//   large: 1280 - 1919
//   xLarge: >= 1920

@Injectable()
export class ScreenService {
  @Output() changed = new EventEmitter()

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe(() => this.changed.emit())
  }

  public get isXSmall() {
    const isXSmol = this.breakpointObserver.isMatched(Breakpoints.XSmall)

    return isXSmol
  }

  public get isSmallOrLess() {
    const isSmol = this.breakpointObserver.isMatched(Breakpoints.Small)
    const isXSmol = this.breakpointObserver.isMatched(Breakpoints.XSmall)

    return isSmol || isXSmol
  }

  public get isMedOrLess() {
    const isSmol = this.breakpointObserver.isMatched(Breakpoints.Small)
    const isXSmol = this.breakpointObserver.isMatched(Breakpoints.XSmall)
    const isMed = this.breakpointObserver.isMatched(Breakpoints.Medium)

    return isSmol || isXSmol || isMed
  }

  public get isLargeOrGreater() {
    const isLarge = this.breakpointObserver.isMatched(Breakpoints.Large)
    const isXLarge = this.breakpointObserver.isMatched(Breakpoints.XLarge)

    return isLarge || isXLarge
  }

  public get isXLarge() {
    const isXLarge = this.breakpointObserver.isMatched(Breakpoints.XLarge)

    return isXLarge
  }

  public get sizes(): Record<string, boolean> {
    return {
      'screen-x-small': this.breakpointObserver.isMatched(Breakpoints.XSmall),
      'screen-small': this.breakpointObserver.isMatched(Breakpoints.Small),
      'screen-medium': this.breakpointObserver.isMatched(Breakpoints.Medium),
      'screen-large': this.isLargeOrGreater,
    }
  }
}
