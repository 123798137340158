///  'FORECAST TASKS - IN PROGRESS' DATASOURCE  ///

import { DateUtilsService } from 'src/app/shared/services/date-utils.service'
import { ChartConfig, ChartField } from '../chart.model'
import { NUM_MATCHED_ITEMS_VALUE_FIELD } from '../constants'
import { RecalcCompletionForecast } from '../dto/recalc-completion-forecast.dto'

export const FORECAST_TASKS_IN_PROGRESS_ARGUMENT_FIELDS: ChartField[] = [
  {
    dataField: 'forecastStartDate',
    displayName: 'Forecast Start Date',
    dataType: 'date',
  },
]

export const FORECAST_TASKS_IN_PROGRESS_DATE_FILTER_FIELDS: ChartField[] = [
  {
    dataField: 'forecastCompletionDate',
    displayName: 'Forecast Completion Date',
    dataType: 'date',
  },
  {
    dataField: 'forecastStartDate',
    displayName: 'Forecast Start Date',
    dataType: 'date',
  },
]

export const FORECAST_TASKS_IN_PROGRESS_FILTER_FIELDS: ChartField[] = [
  {
    dataField: 'siteManager',
    displayName: 'Site Manager',
    dataType: 'string',
  },
  {
    dataField: 'division',
    displayName: 'Division',
    dataType: 'string',
  },
  {
    dataField: 'houseType',
    displayName: 'House Type',
    dataType: 'string',
  },
]

export const FORECAST_TASKS_IN_PROGRESS_VALUE_FIELDS: ChartField[] = [
  NUM_MATCHED_ITEMS_VALUE_FIELD,
]

export const FORECAST_TASK_IN_PROGRESS_GROUP_KEY_FUNC = (
  cashFlowItem: RecalcCompletionForecast,
  config: ChartConfig
) => {
  if (!config) throw Error('forecast task in progress group func requires config object')

  // datasource-provider::convertAndOrderDates has already converted this to date
  const start = cashFlowItem.forecastStartDate as any as Date
  const end = new Date(cashFlowItem.forecastCompletionDate)
  return DateUtilsService.getPeriodKeysBetween(start, end, config.dateAxisTypeId)
}

export const FORECAST_TASKS_IN_PROGRESS_SERIES_GROUP_FIELDS: ChartField[] =
  FORECAST_TASKS_IN_PROGRESS_FILTER_FIELDS.filter(
    (jf) => jf.dataType === 'string' || jf.dataType === 'boolean'
  )
