import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days2';
dayjs.extend(dayjsBusinessDays);
import { HolidaysApiService } from './api/holidays.api.service';


@Injectable({
  providedIn: 'root'
})
export class HolidaysService {

  constructor(private holidaysApi: HolidaysApiService) {}

  setupHolidays() {
    this.holidaysApi.getHolidays().subscribe(holidays => {
      dayjs.setHolidayFormat('YYYY-MM-DD');
      const holidayDates = holidays
        .map(h => dayjs(h.date)
        .format('YYYY-MM-DD'));
      dayjs.setHolidays(holidayDates);
    });
  }

}
