import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ScreenService } from '../../../shared/services'
import { ChartDatasourceProviderService } from '../../../shared/services/chart-datasource-provider.service'
import { DateUtilsService, DxTimeInterval } from '../../../shared/services/date-utils.service'
import { LogService } from '../../../shared/services/log.service'
import { NotificationService } from '../../../shared/services/notification.service'
import { UtilsService } from '../../../shared/services/utils.service'
import { ChartConfig, ChartValue, GenericChartItem } from '../../../types/chart.model'
import { ChartType, DateAxisType } from '../../../types/enums'
import { DashboardService } from '../dashboard/dashboard.service'
import { PointDialogComponent } from './point-dialog/point-dialog.component'

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: [],
})
export class ChartComponent implements OnInit {
  @Input() cardId: number | undefined

  chartConfig: ChartConfig | undefined
  data: GenericChartItem[] = []
  dynamicSeries?: ChartValue[]

  chartColour: string
  dxChartTypeString = 'Bar'
  argumentTickInterval: DxTimeInterval | undefined
  multipleSeries = false

  error = false
  noItems = false
  loading = true

  constructor(
    private dashboardService: DashboardService,
    private logger: LogService,
    private notiService: NotificationService,
    private dateUtils: DateUtilsService,
    private utils: UtilsService,
    private screen: ScreenService,
    private chartData: ChartDatasourceProviderService,
    private dialog: MatDialog
  ) {
    this.chartColour = this.utils.getThemePrimaryColour()
  }

  ngOnInit() {
    if (this.cardId) {
      this.chartConfig = this.dashboardService.getChartConfig(this.cardId)

      if (this.chartConfig.chartColour) {
        this.chartColour = this.chartConfig.chartColour
      }

      this.multipleSeries = this.utils.isMultipleSeries(this.chartConfig)

      this.dxChartTypeString = ChartType[this.chartConfig.chartTypeId]
      this.argumentTickInterval = this.getTickInterval(this.chartConfig.dateAxisTypeId)

      this.provideData()
    }
  }

  provideData() {
    this.chartData.provideData(this.chartConfig!).subscribe({
      next: (res) => {
        this.data = res.data
        this.dynamicSeries = res.dynamicSeries

        if (this.multipleSeries && this.dxChartTypeString == 'Bar')
          this.dxChartTypeString = 'stackedBar'

        if (this.data.length === 0) this.noItems = true

        this.error = false
        this.loading = false
      },
      error: (err) => {
        this.error = true
        this.loading = false
        this.notiService.handleError(err)
        this.logger.log('chart', 'onInit', err)
      },
    })
  }

  pointClick(e: any) {
    if (!this.screen.isXSmall) {
      e.target.hideTooltip()

      this.dialog.open(PointDialogComponent, {
        data: {
          chartItem: e.target.data,
          chartConfig: this.chartConfig,
          valueText: e.target.value,
        },
        width: '1000px',
      })
    }
  }

  customiseArgumentLabel = (e: any) => {
    if (!this.chartConfig?.argumentFieldIsDate) {
      return e.valueText
    }

    const date = e.value as Date
    const isFirst = date.getTime() === e.min.getTime()
    const isLast = date.getTime() === e.max.getTime()
    const onlyOneGraphArg = e.max.getTime() === e.min.getTime()

    if (this.chartConfig?.dateAxisTypeId === DateAxisType.Monthly && onlyOneGraphArg) {
      const month = date.toLocaleString('default', { month: 'long' })
      return `${month} ${date.getFullYear()}`
    } else if (this.chartConfig?.dateAxisTypeId === DateAxisType.Weekly && (isFirst || isLast)) {
      const month = date.toLocaleString('default', { month: 'short' })
      return `${month} ${date.getDate()} ${date.getFullYear()}`
    } else if (this.chartConfig?.dateAxisTypeId === DateAxisType.Yearly) {
      return this.dateUtils.getDateYearString(date)
    } else {
      return e.valueText
    }
  }

  private getTickInterval(grouping: DateAxisType): DxTimeInterval | undefined {
    if (!this.chartConfig?.argumentFieldIsDate) {
      return undefined
    }

    return this.dateUtils.getDateGroupingPeriodName(grouping)
  }
}
