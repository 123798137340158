import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { LayoutModule } from '@angular/cdk/layout'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { HTTP_INTERCEPTORS } from '@angular/common/http'

import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser'
import {
  MsalModule,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
  MsalBroadcastService,
  MsalRedirectComponent,
  MsalGuard,
} from '@azure/msal-angular'

import { AppComponent } from './app.component'
import { SideNavOuterToolbarModule, SingleCardModule } from './layouts'
import { FooterModule } from './shared/components'
import { ScreenService } from './shared/services'
import { AppRoutingModule } from './app-routing.module'
import { environment } from 'src/environments/environment'
import { LoginModule } from './shared/components/login/login.module'
import { HomeModule } from './pages/home/home.module'

import config from 'devextreme/core/config'
import { licenseKey } from '../devextreme-license'
config({ licenseKey })

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1

export function loggerCallback(logLevel: LogLevel, message: string) {
  if (logLevel !== LogLevel.Info) {
    console.error(message)
    // how to log to a service?
  } else {
    // console.log(message);
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientID,
      authority: environment.authority,
      redirectUri: environment.applicationUrl + 'auth',
      postLogoutRedirectUri: environment.applicationUrl,
      knownAuthorities: [environment.authorityDomain],
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  })
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>()
  protectedResourceMap.set(environment.apiUrl, environment.consentScopes)

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  }
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.consentScopes],
    },
    loginFailedRoute: '/login',
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    SideNavOuterToolbarModule,
    SingleCardModule,
    FooterModule,
    LoginModule,
    HomeModule,

    AppRoutingModule,

    LayoutModule,

    MsalModule,
  ],
  providers: [
    ScreenService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
