import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, forkJoin, of } from 'rxjs'
import { map, tap, catchError } from 'rxjs/operators'
import { AuthService } from '../auth.service'
import { CashflowDto } from 'src/app/types/dto/cashflow.dto'
import { environment } from 'src/environments/environment'
import { HttpHelperService } from '../http.service'
import { LogService } from '../log.service'
import { RecalcCompletionForecast } from 'src/app/types/dto/recalc-completion-forecast.dto'
import { ForecastTaskData } from 'src/app/types/dto/forecast-task-data.dto'
import { ForecastJobData } from 'src/app/types/dto/forecast-job-data.dto'
import { TimedCache } from '../timed-cache'

@Injectable({
  providedIn: 'root',
})
export class JobTaskForecastsService {
  private cachedCashflow = new TimedCache<CashflowDto[]>()
  private cachedRecalc = new TimedCache<RecalcCompletionForecast[]>()

  constructor(
    private httpHelper: HttpHelperService,
    private auth: AuthService,
    private logger: LogService,
    private http: HttpClient
  ) {}

  getCashflow(expectedCancellation?: number): Observable<CashflowDto[]> {
    const comp = this.auth.getCurrentCompany()
    if (!comp) {
      this.logger.log('job-task-forecasts.service', 'getCashflow', null, 'no company')
      throw Error('no company set in getCashflow')
    }

    const cancellationString = expectedCancellation ? expectedCancellation.toString() : ''
    const cacheKey = comp.id + cancellationString

    const cachedItem = this.cachedCashflow.getCacheItem(cacheKey)
    if (cachedItem) {
      return cachedItem
    }

    let url = environment.apiUrl + 'job-task-forecasts/cashflow'
    if (expectedCancellation != null && expectedCancellation > 0)
      url += `?expectedPercentCancellation=${expectedCancellation}`

    const obs$ = this.http.get<CashflowDto[]>(url, this.httpHelper.httpOptions)

    return this.cachedCashflow.setCacheItem(cacheKey, obs$)
  }

  getRecalcCompletion() {
    const comp = this.auth.getCurrentCompany()
    if (!comp) {
      this.logger.log('job-task-forecasts.service', 'getRecalcCompletion', null, 'no company')
      throw Error('No company set in getRecalcCompletion')
    }

    const cachedItem = this.cachedRecalc.getCacheItem(comp.id)
    if (cachedItem) {
      return cachedItem
    }

    const forecastTaskData = this.getRecalcCompletionData()
    const forecastJobData = this.getForecastJobData()

    return forkJoin([forecastTaskData, forecastJobData])
      .pipe(
        map(([tasks, jobs]) => {
          const recalcCompletion: RecalcCompletionForecast[] = []
          jobs.forEach((job, index1) => {
            const jobTasks = tasks.filter((t) => t.jobId === job.jobId)
            jobTasks.forEach((task, index2) => {
              recalcCompletion.push({
                id: index1 + '.' + index2,
                jobId: job.jobId,
                jobNumber: job.jobNumber,
                taskMasterId: task.taskMasterId,
                forecastStartDate: task.forecastStartDate,
                forecastCompletionDate: task.forecastCompletionDate,
                siteManager: job.siteManager,
                division: job.division,
                houseType: job.houseType,
              })
            })
          })
          return recalcCompletion
        })
      )
      .pipe(
        tap((recalcCompletion) => {
          this.cachedRecalc.setCacheItem(comp.id, of(recalcCompletion))
        }),
        catchError((error) => {
          throw Error(error)
        })
      )
  }

  private getRecalcCompletionData() {
    const url = environment.apiUrl + 'job-task-forecasts/recalc-completion?standardJobsOnly=true'

    const obs$ = this.http.patch<ForecastTaskData[]>(url, null, this.httpHelper.httpOptions)

    return obs$
  }

  private getForecastJobData() {
    const url = environment.apiUrl + 'job-task-forecasts/forecast-job-data?standardJobsOnly=true'

    const obs$ = this.http.get<ForecastJobData[]>(url, this.httpHelper.httpOptions)

    return obs$
  }
}
