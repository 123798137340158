import { ChartField } from 'src/app/types/chart.model'
import {
  JOBNUM_FIELD,
  JOBNUM_LABEL,
  NUM_MATCHED_ITEMS_VALUE_FIELD,
} from 'src/app/types/constants'
import { DashboardJobDtoBase } from 'src/app/types/dto/dashboard-job.dto'

/** flattens out the job roles from DashboardJobDto */
export interface ProcessedDashboardJob extends DashboardJobDtoBase {
  // any number of roles [the attribute] and the person assigned to that role [value]
  [role: string]: any
}

export const JOB_ALL_FIELDS: ChartField[] = [
  {
    dataField: JOBNUM_FIELD,
    displayName: JOBNUM_LABEL,
    dataType: 'string',
  },
  {
    dataField: 'contractName',
    displayName: 'Contract Name',
    dataType: 'string',
  },
  {
    dataField: 'council',
    displayName: 'Council',
    dataType: 'string',
  },
  {
    dataField: 'estate',
    displayName: 'Estate',
    dataType: 'string',
  },
  {
    dataField: 'division',
    displayName: 'Division',
    dataType: 'string',
  },
  {
    dataField: 'houseType',
    displayName: 'House Type',
    dataType: 'string',
  },
  {
    dataField: 'landZoning',
    displayName: 'Land Zoning',
    dataType: 'string',
  },
  {
    dataField: 'landTitleType',
    displayName: 'Land Title Type',
    dataType: 'string',
  },
  {
    dataField: 'landType',
    displayName: 'Land Type',
    dataType: 'string',
  },
  {
    dataField: 'jobSuburbTown',
    displayName: 'Job Suburb/Town',
    dataType: 'string',
  },
  {
    dataField: 'clientSuburbTown',
    displayName: 'Client Suburb/Town',
    dataType: 'string',
  },
  {
    dataField: 'currentActivity',
    displayName: 'Current Activity',
    dataType: 'string',
  },
  {
    dataField: 'tradeRegion',
    displayName: 'Trade Region',
    dataType: 'string',
  },
  {
    dataField: 'houseArea',
    displayName: 'House Area',
    dataType: 'string',
  },
  {
    dataField: 'blockFrontage',
    displayName: 'Block Frontage',
    dataType: 'string',
  },

  {
    dataField: 'isActive',
    displayName: 'Is Active',
    dataType: 'boolean',
  },
  {
    dataField: 'isOnHold',
    displayName: 'Is On Hold',
    dataType: 'boolean',
  },
  {
    dataField: 'isLocked',
    displayName: 'Is Locked',
    dataType: 'boolean',
  },
  {
    dataField: 'isGSTFree',
    displayName: 'Is GST Free',
    dataType: 'boolean',
  },

  {
    dataField: 'lockedDate',
    displayName: 'Locked Date',
    dataType: 'date',
  },
  {
    dataField: 'salesDate',
    displayName: 'Sales Date',
    dataType: 'date',
  },
  {
    dataField: 'contractSignedDate',
    displayName: 'Contract Signed Date',
    dataType: 'date',
  },
  {
    dataField: 'salesQuoteSignedDate',
    displayName: 'Sales Quote Signed Date',
    dataType: 'date',
  },
  {
    dataField: 'quoteDate',
    displayName: 'Quote Date',
    dataType: 'date',
  },
  {
    dataField: 'contractQuoteDate',
    displayName: 'Contract Quote Date',
    dataType: 'date',
  },
  {
    dataField: 'titleDueDate',
    displayName: 'Title Due Date',
    dataType: 'date',
  },
  {
    dataField: 'depositBalanceDue',
    displayName: 'Deposit Balance Due',
    dataType: 'date',
  },
  {
    dataField: 'estimatingCostingDate',
    displayName: 'Estimating Costing Date',
    dataType: 'date',
  },
  {
    dataField: 'completionDate',
    displayName: 'Completion Date',
    dataType: 'date',
  },
  {
    dataField: 'cancellationDate',
    displayName: 'Cancellation Date',
    dataType: 'date',
  },
  {
    dataField: 'handoverDate',
    displayName: 'Handover Date',
    dataType: 'date',
  },
  {
    dataField: 'targetCompletionDate',
    displayName: 'Target Completion Date',
    dataType: 'date',
  },
  {
    dataField: 'forecastCompletionDate',
    displayName: 'Forecast Completion Date',
    dataType: 'date',
  },

  {
    dataField: 'contractPrice',
    displayName: 'Contract Price',
    dataType: 'number',
  },
  {
    dataField: 'depositAmount',
    displayName: 'Deposit Amount',
    dataType: 'number',
  },
  {
    dataField: 'basePrice',
    displayName: 'Base Price',
    dataType: 'number',
  },
  {
    dataField: 'budgetAmount',
    displayName: 'Budget Amount',
    dataType: 'number',
  },
  {
    dataField: 'jobMargin',
    displayName: 'Job Margin',
    dataType: 'number',
  },
  {
    dataField: 'variationMargin',
    displayName: 'Variation Margin',
    dataType: 'number',
  },
  {
    dataField: 'gstRate',
    displayName: 'GST Rate',
    dataType: 'number',
  },
  {
    dataField: 'depositPaid',
    displayName: 'Deposit Paid',
    dataType: 'number',
  },
  {
    dataField: 'salesQuotePrice',
    displayName: 'Sales Quote Price',
    dataType: 'number',
  },

  {
    dataField: 'postContractVariations',
    displayName: 'postContractVariations',
    dataType: 'number',
  },
  {
    dataField: 'contractTotalExcGST',
    displayName: 'contractTotalExcGST',
    dataType: 'number',
  },
  {
    dataField: 'jobCostEcxGST',
    displayName: 'jobCostEcxGST',
    dataType: 'number',
  },
  {
    dataField: 'orderExtraTotal',
    displayName: 'orderExtraTotal',
    dataType: 'number',
  },
  {
    dataField: 'extraIncomeIncGST',
    displayName: 'extraIncomeIncGST',
    dataType: 'number',
  },
  {
    dataField: 'markUpPercent',
    displayName: 'markUpPercent',
    dataType: 'number',
  },
  {
    dataField: 'marginPercent',
    displayName: 'marginPercent',
    dataType: 'number',
  },
]

export const JOB_FILTER_FIELDS: ChartField[] = JOB_ALL_FIELDS.filter(
  (jf) => jf.dataType === 'string' || jf.dataType === 'boolean' || jf.dataType === 'date'
)

export const JOB_DATE_FILTER_FIELDS: ChartField[] = JOB_ALL_FIELDS.filter(
  (jf) => jf.dataType === 'date'
)

export const JOB_ARGUMENT_FIELDS: ChartField[] = JOB_ALL_FIELDS.filter(
  (jf) => jf.dataType === 'string' || jf.dataType === 'date'
)

export const JOB_VALUE_FIELDS: ChartField[] = [NUM_MATCHED_ITEMS_VALUE_FIELD].concat(
  JOB_ALL_FIELDS.filter((jf) => jf.dataType === 'number')
)

export const JOB_SERIES_GROUP_FIELDS: ChartField[] = JOB_ALL_FIELDS.filter(
  (jf) => jf.dataType === 'string' || jf.dataType === 'boolean'
)
