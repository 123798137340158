import { Injectable } from '@angular/core'
import {
  CASHFLOW_ARGUMENT_FIELDS,
  CASHFLOW_DATE_FILTER_FIELDS,
  CASHFLOW_FILTER_FIELDS,
  CASHFLOW_SERIES_GROUP_FIELDS,
  CASHFLOW_TOOLTIP_VALUE_STRING,
  CASHFLOW_VALUE_FIELDS,
} from 'src/app/types/datasources/cashflow.model'
import { ChartField } from 'src/app/types/chart.model'
import {
  CLAIM_ARGUMENT_FIELDS,
  CLAIM_DATE_FILTER_FIELDS,
  CLAIM_FILTER_FIELDS,
  CLAIM_SERIES_GROUP_FIELDS,
  CLAIM_VALUE_FIELDS,
} from 'src/app/types/datasources/claims.model'
import {
  DEFAULT_CHART_TYPE,
  DEFAULT_DATE_GROUPING,
  DEFAULT_VALUE_AGGREGATION,
  FUTURE_DATE_RANGE_TYPES,
  JOBNUM_FIELD,
  PAST_DATE_RANGE_TYPES,
} from 'src/app/types/constants'
import { ChartDataSource, ChartType, DateAxisType, ValueAggregationType } from 'src/app/types/enums'
import {
  JOB_ARGUMENT_FIELDS,
  JOB_DATE_FILTER_FIELDS,
  JOB_FILTER_FIELDS,
  JOB_SERIES_GROUP_FIELDS,
  JOB_VALUE_FIELDS,
} from 'src/app/types/datasources/job.model'
import {
  JOBTASK_ARGUMENT_FIELDS,
  JOBTASK_DATE_FILTER_FIELDS,
  JOBTASK_FILTER_FIELDS,
  JOBTASK_SERIES_GROUP_FIELDS,
  JOBTASK_VALUE_FIELDS,
} from 'src/app/types/datasources/tasks.model'
import { LogService } from './log.service'
import {
  FORECAST_TASKS_ARGUMENT_FIELDS,
  FORECAST_TASKS_DATE_FILTER_FIELDS,
  FORECAST_TASKS_FILTER_FIELDS,
  FORECAST_TASKS_SERIES_GROUP_FIELDS,
  FORECAST_TASKS_VALUE_FIELDS,
} from 'src/app/types/datasources/forecast-tasks.model'
import { GROUP_KEYS_FUNCTION } from 'src/app/types/constants'
import {
  FORECAST_TASKS_IN_PROGRESS_ARGUMENT_FIELDS,
  FORECAST_TASKS_IN_PROGRESS_DATE_FILTER_FIELDS,
  FORECAST_TASKS_IN_PROGRESS_FILTER_FIELDS,
  FORECAST_TASKS_IN_PROGRESS_SERIES_GROUP_FIELDS,
  FORECAST_TASKS_IN_PROGRESS_VALUE_FIELDS,
  FORECAST_TASK_IN_PROGRESS_GROUP_KEY_FUNC,
} from 'src/app/types/datasources/forecast-tasks-in-progress.model'

@Injectable({
  providedIn: 'root',
})
export class DatasourceConfigService {
  // dynamic - see job.api.service::flattenRoles()
  jobArgumentFields: ChartField[] = [...JOB_ARGUMENT_FIELDS]
  jobFilterFields: ChartField[] = [...JOB_FILTER_FIELDS]

  constructor(private logger: LogService) {}

  isDateArgument(datasource: ChartDataSource, argumentField: string): boolean {
    const argFields = this.getDatasourceArgumentFields(datasource)

    return argFields.filter((o) => o.dataField === argumentField)[0]?.dataType === 'date'
  }

  getArgFieldDisplayName(datasource: ChartDataSource, field: string): string {
    const fields = this.getDatasourceArgumentFields(datasource)

    return fields.filter((o) => o.dataField === field)[0]?.displayName
  }

  getValueFieldDisplayName(datasource: ChartDataSource, field: string): string {
    const fields = this.getDatasourceValueFields(datasource)

    return fields.filter((o) => o.dataField === field)[0]?.displayName
  }

  getDateFilterFieldDisplayName(datasource: ChartDataSource, field?: string): string {
    if (!field) {
      return ''
    }

    const filterFields = this.getDatasourceDateFilterFields(datasource)

    return filterFields.filter((o) => o.dataField === field)[0]?.displayName
  }

  getJobIdField(datasource: ChartDataSource): string {
    switch (datasource) {
      default:
        return JOBNUM_FIELD
    }
  }

  datasourceUsesTaskSelection(datasource: ChartDataSource): boolean {
    switch (datasource) {
      case ChartDataSource.Tasks:
      case ChartDataSource.TaskDurations:
      case ChartDataSource.ForecastedTasks:
      case ChartDataSource.ForecastedTasksInProgress:
        return true
      default:
        return false
    }
  }

  datasourceCustomGroupKeyFunc(
    datasource: ChartDataSource
  ): GROUP_KEYS_FUNCTION | undefined {
    switch (datasource) {
      case ChartDataSource.ForecastedTasksInProgress:
        return FORECAST_TASK_IN_PROGRESS_GROUP_KEY_FUNC
      default:
        return undefined
    }
  }

  getTooltipValueString(datasource: ChartDataSource): string | undefined {
    switch (datasource) {
      case ChartDataSource.Cashflow:
        return CASHFLOW_TOOLTIP_VALUE_STRING
      default:
        return undefined
    }
  }

  getDefaultValueAggregation(datasource: ChartDataSource): ValueAggregationType {
    switch (datasource) {
      case ChartDataSource.Cashflow:
        return ValueAggregationType.Cumulative
      default:
        return DEFAULT_VALUE_AGGREGATION
    }
  }

  getDefaultDateGrouping(datasource: ChartDataSource): DateAxisType {
    switch (datasource) {
      default:
        return DEFAULT_DATE_GROUPING
    }
  }

  getDefaultChartType(datasource: ChartDataSource): ChartType {
    switch (datasource) {
      case ChartDataSource.Cashflow:
        return ChartType.Line
      default:
        return DEFAULT_CHART_TYPE
    }
  }

  getDateRangeOptions(datasource: ChartDataSource) {
    switch (datasource) {
      case ChartDataSource.Cashflow:
      case ChartDataSource.ForecastedTasks:
      case ChartDataSource.ForecastedTasksInProgress:
        return FUTURE_DATE_RANGE_TYPES
      default:
        return PAST_DATE_RANGE_TYPES
    }
  }

  getDatasourceArgumentFields(datasource: ChartDataSource): ChartField[] {
    let fields: ChartField[] = []

    switch (datasource) {
      case ChartDataSource.Tasks:
      case ChartDataSource.TaskDurations:
        fields = JOBTASK_ARGUMENT_FIELDS
        break
      case ChartDataSource.Claims:
        fields = CLAIM_ARGUMENT_FIELDS
        break
      case ChartDataSource.Jobs:
        fields = this.jobArgumentFields
        break
      case ChartDataSource.Cashflow:
        fields = CASHFLOW_ARGUMENT_FIELDS
        break
      case ChartDataSource.ForecastedTasks:
        fields = FORECAST_TASKS_ARGUMENT_FIELDS
        break
      case ChartDataSource.ForecastedTasksInProgress:
        fields = FORECAST_TASKS_IN_PROGRESS_ARGUMENT_FIELDS
        break
      default:
        this.logger.log(
          'datasource-config.service',
          'getDatasourceArgumentFields',
          null,
          'data source not implemented'
        )
        throw new Error('data source not implemented')
    }

    return this.sort(fields)
  }

  getDatasourceFilterFields(datasource: ChartDataSource): ChartField[] {
    let fields: ChartField[] = []

    switch (datasource) {
      case ChartDataSource.Tasks:
      case ChartDataSource.TaskDurations:
        fields = JOBTASK_FILTER_FIELDS
        break
      case ChartDataSource.Claims:
        fields = CLAIM_FILTER_FIELDS
        break
      case ChartDataSource.Jobs:
        fields = this.jobFilterFields
        break
      case ChartDataSource.Cashflow:
        fields = CASHFLOW_FILTER_FIELDS
        break
      case ChartDataSource.ForecastedTasks:
        fields = FORECAST_TASKS_FILTER_FIELDS
        break
      case ChartDataSource.ForecastedTasksInProgress:
        fields = FORECAST_TASKS_IN_PROGRESS_FILTER_FIELDS
        break
      default:
        this.logger.log(
          'datasource-config.service',
          'getDatasourceFilterFields',
          null,
          'data source not implemented'
        )
        throw new Error('data source not implemented')
    }

    return this.sort(fields)
  }

  getDatasourceDateFilterFields(datasource: ChartDataSource): ChartField[] {
    let fields: ChartField[] = []

    switch (datasource) {
      case ChartDataSource.Tasks:
      case ChartDataSource.TaskDurations:
        fields = JOBTASK_DATE_FILTER_FIELDS
        break
      case ChartDataSource.Claims:
        fields = CLAIM_DATE_FILTER_FIELDS
        break
      case ChartDataSource.Jobs:
        fields = JOB_DATE_FILTER_FIELDS
        break
      case ChartDataSource.Cashflow:
        fields = CASHFLOW_DATE_FILTER_FIELDS
        break
      case ChartDataSource.ForecastedTasks:
        fields = FORECAST_TASKS_DATE_FILTER_FIELDS
        break
      case ChartDataSource.ForecastedTasksInProgress:
        fields = FORECAST_TASKS_IN_PROGRESS_DATE_FILTER_FIELDS
        break
      default:
        this.logger.log(
          'datasource-config.service',
          'getDatasourceDateFilterFields',
          null,
          'data source not implemented'
        )
        throw new Error('data source not implemented')
    }

    return this.sort(fields)
  }

  getDatasourceValueFields(datasource: ChartDataSource): ChartField[] {
    let fields: ChartField[] = []

    switch (datasource) {
      case ChartDataSource.Tasks:
      case ChartDataSource.TaskDurations:
        fields = JOBTASK_VALUE_FIELDS
        break
      case ChartDataSource.Claims:
        fields = CLAIM_VALUE_FIELDS
        break
      case ChartDataSource.Jobs:
        fields = JOB_VALUE_FIELDS
        break
      case ChartDataSource.Cashflow:
        fields = CASHFLOW_VALUE_FIELDS
        break
      case ChartDataSource.ForecastedTasks:
        fields = FORECAST_TASKS_VALUE_FIELDS
        break
      case ChartDataSource.ForecastedTasksInProgress:
        fields = FORECAST_TASKS_IN_PROGRESS_VALUE_FIELDS
        break
      default:
        this.logger.log(
          'datasource-config.service',
          'getDatasourceValueFields',
          null,
          'data source not implemented'
        )
        throw new Error('data source not implemented')
    }

    return this.sort(fields)
  }

  getDatasourceSeriesGroupFields(datasource: ChartDataSource): ChartField[] {
    let fields: ChartField[] = []

    switch (datasource) {
      case ChartDataSource.Tasks:
        fields = JOBTASK_ARGUMENT_FIELDS
        break
      case ChartDataSource.TaskDurations:
        fields = JOBTASK_SERIES_GROUP_FIELDS
        break
      case ChartDataSource.Claims:
        fields = CLAIM_SERIES_GROUP_FIELDS
        break
      case ChartDataSource.Jobs:
        fields = JOB_SERIES_GROUP_FIELDS
        break
      case ChartDataSource.Cashflow:
        fields = CASHFLOW_SERIES_GROUP_FIELDS
        break
      case ChartDataSource.ForecastedTasks:
        fields = FORECAST_TASKS_SERIES_GROUP_FIELDS
        break
      case ChartDataSource.ForecastedTasksInProgress:
        fields = FORECAST_TASKS_IN_PROGRESS_SERIES_GROUP_FIELDS
        break
      default:
        this.logger.log(
          'datasource-config.service',
          'getDatasourceSeriesGroupFields',
          null,
          'data source not implemented'
        )
        throw new Error('data source not implemented')
    }

    return this.sort(fields)
  }

  seriesGroupingIsDate(datasource: ChartDataSource, fieldName: string) {
    const fields = this.getDatasourceSeriesGroupFields(datasource)
    const field = fields.filter((f) => f.dataField === fieldName)[0]
    if (!field) throw Error("couldn't find series group field")

    return field.dataType === 'date'
  }

  adjustJobFieldsForRoles(roles: ChartField[]) {
    this.jobArgumentFields = [...JOB_ARGUMENT_FIELDS]
    this.jobFilterFields = [...JOB_FILTER_FIELDS]

    roles.forEach((role) => {
      this.jobArgumentFields.push(role)
      this.jobFilterFields.push(role)
    })
  }

  private sort(fields: ChartField[]): ChartField[] {
    return fields.sort((a, b) => (a.displayName < b.displayName ? -1 : 1))
  }
}
