<div class="grid-container">
  <mat-toolbar color="secondary" class="header">
    <div *ngIf="dashboardsExist" class="dash-select" subscriptSizing="dynamic"
      [ngClass]="{'small-font' : screen.isSmallOrLess, 'smaller-font' : screen.isXSmall}">
      <dx-select-box *ngIf="dashboardsService.dashboards"
        [dataSource]="dashboardsService.dashboards"
        displayExpr="name"
        valueExpr="id"
        [value]="dashboardsService.selectedDashboardId"
        (onValueChanged)="dashboardSelected($event)"
        fieldTemplate="field"
        [inputAttr]="{ 'aria-label': 'Dashboard' }"
        searchExpr="name"
        [searchTimeout]="200"
        [minSearchLength]="1"
        [searchEnabled]="true"
        [showDataBeforeSearch]="true"
        [showClearButton]="!screen.isXSmall"
        (onFocusOut)="selectFocusOut()"
      >
      <div *dxTemplate="let data of 'field'">
        <div class="custom-item">
          <dx-text-box
            [class]="dashboardsService.currentDashIsDefault ? 'dash-name-selected' : 'dash-name'"
            [value]="data && data.name"
            [inputAttr]="{ 'aria-label': 'Dashboard Name' }"
            [readOnly]="false"
          ></dx-text-box>
          <mat-icon *ngIf="dashboardsService.currentDashIsDefault" class="m-l-5 vertical-bottom"
            [ngClass]="{'smaller-icon' : screen.isMedOrLess}">
            favorite
          </mat-icon>
        </div>
      </div>
      <div *dxTemplate="let dash of 'item'" class="dash-list-item">
        <mat-icon *ngIf="dashboardsService.dashIsDefault(dash.id)" class="vertical-middle"
          [ngClass]="{'smaller-icon' : screen.isMedOrLess}">
          favorite
        </mat-icon>
        {{dash.name}}
      </div></dx-select-box>
    </div>
    <span class="header-btns">
      <ng-container *ngIf="dashboardsExist">
        <button *ngIf="!screen.isSmallOrLess else fabEdit" mat-stroked-button
          color="primary" class="m-l-15 primary-btn mat-elevation-z0" (click)="addEditDashboard(false)">
          Edit Dashboard
        </button>
      </ng-container>
      <button *ngIf="!screen.isSmallOrLess else fabAdd" mat-stroked-button
        color="primary" class="vertical-middle primary-btn mat-elevation-z0" (click)="addEditDashboard(true)">
        Add Dashboard
      </button>
    </span>
  </mat-toolbar>

  <app-spinner *ngIf="loading; else loaded"></app-spinner>

  <ng-template #loaded>
    <app-dashboard *ngIf="dashboardsExist" [dashboardId]="dashboardsService.selectedDashboardId"></app-dashboard>
  
    <div *ngIf="!error && !dashboardsExist" class="info-box m-50">
      You don't appear to have any Dashboards.
      You can create one with the button above.
    </div>
    <div *ngIf="error" class="error-box m-50">
      There was an error fetching the dashboards.
      <br>Please try again in a moment.
    </div>
  </ng-template>
</div>

<!-- no way to conditionally choose material button type atm :( -->
<ng-template #fabEdit>
  <button mat-mini-fab
    color="primary" class="m-l-15 mat-elevation-z0" (click)="addEditDashboard(false)">
    <mat-icon class="vertical-middle">edit</mat-icon>
  </button>
</ng-template>

<ng-template #fabAdd>
  <button mat-mini-fab
    color="primary" class="vertical-middle mat-elevation-z0" (click)="addEditDashboard(true)">
    <mat-icon class="vertical-middle">add</mat-icon>
  </button>
</ng-template>