import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ScreenService } from '../../shared/services'
import { AppArea, AppStateService } from '../../shared/services/app-state.service'
import { AddEditDashboardComponent } from './add-edit-dashboard/add-edit-dashboard.component'
import { DashboardsService } from './dashboards.service'
import { HolidaysService } from '../../shared/services/holidays.service'
import { DxSelectBoxComponent } from 'devextreme-angular'

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss'],
})
export class DashboardsComponent implements OnInit, OnDestroy {
  dashboardsExist = false
  error = false
  loading = true
  private readonly destroying = new Subject<null>()

  @ViewChild(DxSelectBoxComponent) dashSelect!: DxSelectBoxComponent

  constructor(
    public screen: ScreenService,
    private appState: AppStateService,
    public dashboardsService: DashboardsService,
    private holidaysService: HolidaysService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.appState.setAreaSelected(AppArea.dashboard)

    this.dashboardsService.dashboardsUpdated
      .pipe(takeUntil(this.destroying))
      .subscribe((success) => {
        if (success) {
          this.error = false
          this.loading = false
          this.dashboardsExist = this.dashboardsService.dashboardsExist
        } else {
          this.error = true
          this.loading = false
        }
      })

    this.holidaysService.setupHolidays()

    this.dashboardsService.initDashboards()
  }

  dashboardSelected(e: any) {
    if (e.value) {
      this.dashboardsService.setSelectedDashboard(e.value)
      this.dashSelect.instance.blur()
    } else this.dashSelect.instance.focus() // focus on clear button
  }

  // put the dash name back if focusing out after clearing
  selectFocusOut() {
    if (!this.dashSelect.value) this.dashSelect.value = this.dashboardsService.selectedDashboardId
  }

  addEditDashboard(adding: boolean) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.panelClass = 'w-500'
    dialogConfig.data = {
      adding: adding,
    }

    this.dialog.open(AddEditDashboardComponent, dialogConfig)
  }

  ngOnDestroy(): void {
    this.destroying.next(null)
    this.destroying.complete()
  }
}
