import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  CloneCardRequest,
  PatchDashCardRequest,
} from 'src/app/types/request/dashboard.req'
import { DashCardDto } from 'src/app/types/dto/dashboard.types.dto'
import { environment } from 'src/environments/environment'
import { HttpHelperService } from '../http.service'

@Injectable({
  providedIn: 'root',
})
export class CardApiService {
  appBaseURL = environment.apiUrl + 'dash-cards/'

  constructor(private httpHelper: HttpHelperService, private http: HttpClient) {}

  // getCard() {
  // }

  // addCard() {
  // }

  // deleteCard() {
  // }

  cloneCard(req: CloneCardRequest) {
    return this.http.post<DashCardDto>(
      this.appBaseURL + 'clone',
      req,
      this.httpHelper.httpOptions
    )
  }

  // does not currently re-order other cards so only using to update chart data
  // adding, deleting, re-ordering, re-sizing done via patch-dashboard
  editCard(cardId: number, card: PatchDashCardRequest) {
    return this.http.patch<DashCardDto>(
      this.appBaseURL + cardId,
      card,
      this.httpHelper.httpOptions
    )
  }
}
