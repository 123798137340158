import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { JobTask, TaskMaster, TaskStatus } from 'src/app/types/dto/tasks.dto'
import { ProcessedJobTask } from 'src/app/types/datasources/tasks.model'
import { environment } from 'src/environments/environment'
import { AuthService } from '../auth.service'
import { HttpHelperService } from '../http.service'
import { TimedCache } from '../timed-cache'
import { LogService } from '../log.service'

@Injectable({
  providedIn: 'root',
})
export class TasksApiService {
  private cachedTaskMasters = new TimedCache<TaskMaster[]>()
  private cachedTasks = new TimedCache<ProcessedJobTask[]>()

  constructor(
    private httpHelper: HttpHelperService,
    private auth: AuthService,
    private logger: LogService,
    private http: HttpClient
  ) {}

  getGroupedTaskMastersForCurrentCompany(activeOnly = true): Observable<TaskMaster[]> {
    const comp = this.auth.getCurrentCompany()
    if (!comp) {
      this.logger.log(
        'tasks.api',
        'getGroupedTaskMastersForCurrentCompany',
        null,
        'no company set'
      )
      throw Error('no company set in getGroupedTaskMastersForCurrentCompany')
    }
    if (this.cachedTaskMasters.getCacheItem(comp.id)) {
      return this.cachedTaskMasters.getCacheItem(comp.id)!
    }

    let obs$ = this.http
      .get<TaskMaster[]>(environment.apiUrl + 'task-masters', this.httpHelper.httpOptions)
      .pipe(map((res) => res.sort((a, b) => a.taskTitle.localeCompare(b.taskTitle))))

    if (activeOnly) {
      obs$ = obs$.pipe(map((res) => res.filter((t: any) => t.isActive)))
    }

    return this.cachedTaskMasters.setCacheItem(comp.id, obs$)
  }

  getTasksForTaskmaster(taskMasterId: number): Observable<ProcessedJobTask[]> {
    if (taskMasterId === -1) {
      return this.mock1()
    }
    if (taskMasterId === -2) {
      return this.mock2()
    }

    if (this.cachedTasks.getCacheItem(taskMasterId)) {
      return this.cachedTasks.getCacheItem(taskMasterId)!
    }

    const obs$ = this.http
      .get<JobTask[]>(
        environment.apiUrl + 'job-tasks/task-master/' + taskMasterId,
        this.httpHelper.httpOptions
      )
      .pipe(
        map((res) =>
          res.map((jt) => {
            return {
              ...jt,
              startDate: new Date(jt.startDate),
              endDate: jt.endDate ? new Date(jt.endDate) : undefined,
              status: TaskStatus[jt.statusId],
            }
          })
        )
      )

    return this.cachedTasks.setCacheItem(taskMasterId, obs$)
  }

  mock1() {
    return of([
      {
        id: 1,
        jobId: 200,
        jobNumber: '200',
        startDate: new Date('2022-01-01T00:00:00'),
        endDate: new Date('2022-02-01T00:00:00'),
        status: 'blah',
        allocatedTo: '',
        division: '',
        vendor: '',
        salesRep: '',
        siteManager: '',
      },
      {
        id: 2,
        jobId: 200,
        jobNumber: '200',
        startDate: new Date('2022-01-03T00:00:00'),
        endDate: new Date('2022-02-03T00:00:00'),
        status: 'blah',
        allocatedTo: '',
        division: '',
        vendor: '',
        salesRep: '',
        siteManager: '',
      },
      {
        id: 3,
        jobId: 100,
        jobNumber: '100',
        startDate: new Date('2022-02-01T00:00:00'),
        endDate: new Date('2022-03-01T00:00:00'),
        status: 'blah',
        allocatedTo: '',
        division: '',
        vendor: '',
        salesRep: '',
        siteManager: '',
      },
      {
        id: 3,
        jobId: 201,
        jobNumber: '201',
        startDate: new Date('2022-02-01T00:00:00'),
        endDate: new Date('2022-03-01T00:00:00'),
        status: 'blah',
        allocatedTo: '',
        division: '',
        vendor: '',
        salesRep: '',
        siteManager: '',
      },
      {
        id: 6,
        jobId: 500,
        jobNumber: '500',
        startDate: new Date('2022-03-20T00:00:00'),
        endDate: new Date('2022-04-20T00:00:00'),
        status: 'blah',
        allocatedTo: '',
        division: '',
        vendor: '',
        salesRep: '',
        siteManager: '',
      },
    ])
  }

  mock2() {
    return of([
      // date missing (task not finished) so entire job 100 shouldnt count
      {
        id: 4,
        jobId: 100,
        jobNumber: '100',
        startDate: new Date('2022-01-01T00:00:00'),
        endDate: undefined,
        status: 'blah',
        allocatedTo: '',
        division: '',
        vendor: '',
        salesRep: '',
        siteManager: '',
      },
      {
        id: 5,
        jobId: 100,
        jobNumber: '100',
        startDate: new Date('2022-03-03T00:00:00'),
        endDate: new Date('2022-04-03T00:00:00'),
        status: 'blah',
        allocatedTo: '',
        division: '',
        vendor: '',
        salesRep: '',
        siteManager: '',
      },
      {
        id: 6,
        jobId: 200,
        jobNumber: '200',
        startDate: new Date('2022-03-09T00:00:00'),
        endDate: new Date('2022-04-09T00:00:00'),
        status: 'blah',
        allocatedTo: '',
        division: '',
        vendor: '',
        salesRep: '',
        siteManager: 'bob',
      },
      {
        id: 6,
        jobId: 200,
        jobNumber: '200',
        startDate: new Date('2022-03-09T00:00:00'),
        endDate: new Date('2022-04-10T00:00:00'),
        status: 'blah',
        allocatedTo: '',
        division: '',
        vendor: '',
        salesRep: '',
        siteManager: 'sally',
      },
      // later task for job 201 is in next period, this one shouldn't count
      {
        id: 6,
        jobId: 201,
        jobNumber: '201',
        startDate: new Date('2022-03-15T00:00:00'),
        endDate: new Date('2022-04-15T00:00:00'),
        status: 'blah',
        allocatedTo: '',
        division: '',
        vendor: '',
        salesRep: '',
        siteManager: '',
      },
      // no task 1 equivalent so ignored (shows as 0 in graph if grouping by job):
      {
        id: 6,
        jobId: 202,
        jobNumber: '202',
        startDate: new Date('2022-03-20T00:00:00'),
        endDate: new Date('2022-04-20T00:00:00'),
        status: 'blah',
        allocatedTo: '',
        division: '',
        vendor: '',
        salesRep: '',
        siteManager: '',
      },
      // period 2 (JUNE)
      {
        id: 6,
        jobId: 500,
        jobNumber: '500',
        startDate: new Date('2022-05-20T00:00:00'),
        endDate: new Date('2022-06-20T00:00:00'),
        status: 'blah',
        allocatedTo: '',
        division: '',
        vendor: '',
        salesRep: '',
        siteManager: 'sally',
      },
      {
        id: 6,
        jobId: 201,
        jobNumber: '201',
        startDate: new Date('2022-05-20T00:00:00'),
        endDate: new Date('2022-06-25T00:00:00'),
        status: 'blah',
        allocatedTo: '',
        division: '',
        vendor: '',
        salesRep: '',
        siteManager: 'bob',
      },
    ])
  }
}
