import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';

import { BehaviorSubject, Subject } from 'rxjs';
import { CompanyDto } from 'src/app/types/dto/company.dto';
import { UserDto, UserTypeEnum } from 'src/app/types/dto/user.dto';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  // truth engine user as opposed to msal user
  // before a company is selected, will only contain the email
  currentUser = new BehaviorSubject<UserDto | undefined>(undefined);

  currentCompany = new BehaviorSubject<CompanyDto | undefined>(undefined);

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration)
  { }

  // fully signed-in with TE user, not just msal signed in
  get isSignedIn() { return this.currentUser.value?.id }

  signOut() {
    this.currentUser.next(undefined);

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        this.msalService.logoutPopup({
            mainWindowRedirectUri: '/'
        });
    } else {
        this.msalService.logoutRedirect();
    }
  }

  setCurrentCompany(company: CompanyDto) {
    this.currentCompany.next(company);
  }

  getCurrentCompany() {
    return this.currentCompany.value;
  }

  getMsalUserEmail() {
    /* assuming 1 active account at a time */
    const accs = this.msalService.instance.getAllAccounts();
    /* assuming one email per account */
    return accs.length > 0 ? (<any>accs[0].idTokenClaims)['emails'][0] : null;
  }

  getUserEmail() {
    return this.currentUser.value?.email;
  }

  getCurrentUser() {
    return this.currentUser.value;
  }

  setCurrentUser(inUser: any) {
    this.currentUser.next(inUser);
  }

  isSuperUser() {
    return this.getCurrentUser()?.isSuperUser;
  }

  isAdminOrSuper() {
    return this.currentCompany.value?.userTypeId === UserTypeEnum.Admin || this.getCurrentUser()?.isSuperUser;
  }

  isClient() {
    return this.currentCompany.value?.userTypeId === UserTypeEnum.Client;
  }

  isAssociate() {
    return this.currentCompany.value?.userTypeId === UserTypeEnum.Associate;
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
