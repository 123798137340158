import { ChartConfig, ChartField } from './chart.model'
import { ChartType, DateAxisType, DateRangeType, ValueAggregationType } from './enums'

export const DEFAULT_VALUE_FIELD = 'default'

export const JOBNUM_FIELD = 'jobNumber'
export const JOBNUM_LABEL = 'Job Number'

export const DEFAULT_RANGE = 'All Time'

export const PAST_DATE_RANGE_TYPES = Object.keys(DateRangeType).filter(
  (k) => isNaN(Number(k)) && !k.includes('Next')
)
export const FUTURE_DATE_RANGE_TYPES = Object.keys(DateRangeType).filter(
  (k) => isNaN(Number(k)) && !k.includes('Last')
)

// can be overridden per-datasource in types/*.model.ts files
export const DEFAULT_VALUE_AGGREGATION = ValueAggregationType.Sum
export const DEFAULT_DATE_GROUPING = DateAxisType.Monthly
export const DEFAULT_CHART_TYPE = ChartType.Bar

export const NUM_MATCHED_ITEMS_VALUE_FIELD: ChartField = {
  dataField: DEFAULT_VALUE_FIELD,
  displayName: '** Use number of matched items **',
  dataType: 'number',
}

export type GROUP_KEYS_FUNCTION = (item: any, config: ChartConfig) => (Date | string)[]
