<dx-chart *ngIf="!error && !loading && !noItems" style="height: 100%"
  [dataSource]="data" (onPointClick)="pointClick($event)">
  <dxi-series *ngIf="!multipleSeries"
      argumentField="groupField"
      valueField="value"
      [type]="dxChartTypeString"
      [color]="chartColour"
    >
    </dxi-series>
  <dxi-series
      *ngFor="let s of dynamicSeries"
      argumentField="groupField"
      [valueField]="s.value"
      [name]="s.label"
      [type]="dxChartTypeString"
      [color]="multipleSeries ? null : chartColour"
    >
    </dxi-series>

  <dxi-value-axis [visible]="true" [allowDecimals]="false"
    [title]="chartConfig?.valueAxisTitle || ''">
    <dxi-constant-line *ngIf="chartConfig?.targetValue"
      [value]="chartConfig!.targetValue!"
      [label]="{ text: 'Target Value'}"
      color="red" dashStyle="dash">
    </dxi-constant-line>
  </dxi-value-axis>

  <dxo-argument-axis [tickInterval]="$any(argumentTickInterval)">
    <dxo-label [visible]="true" [customizeText]="customiseArgumentLabel"></dxo-label>
  </dxo-argument-axis>

  <dxo-tooltip [enabled]="true" contentTemplate="tooltipTemplate"
    [zIndex]="2000"></dxo-tooltip>
  <div *dxTemplate="let info of 'tooltipTemplate'">
    <app-point-info
      [chartConfig]="chartConfig"
      [chartItem]="info.point.data"
      [valueText]="info.valueText"></app-point-info>
  </div>
  <dxo-legend [visible]="multipleSeries"
      verticalAlignment="bottom"
      horizontalAlignment="center"
      itemTextPosition="bottom"
    >
  </dxo-legend>
</dx-chart>

<app-spinner *ngIf="loading" class="m-t-50"></app-spinner>

<div *ngIf="error" class="error-box m-t-50">
  Could not load chart. Please try again in a moment.
</div>

<div *ngIf="noItems" class="info-box m-t-50">
  There are no items to display in this chart. 
  <br>Please check your filters.
</div>
