export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home',
  },
  {
    text: 'Dashboards',
    path: '/dashboard',
    icon: 'chart',
  },
  // {
  //   text: 'Training Docs',
  //   path: '/training',
  //   icon: 'user'
  // },
  {
    text: 'Applications',
    icon: 'globe',
    items: [
      {
        text: 'Truth Engine',
        icon: 'assets/main_app.ico',
        link: 'truthEngineAppUrl',
      },
      {
        text: 'Tracking',
        icon: 'assets/tracking_app.ico',
        link: 'trackingAppUrl',
      },
      {
        text: 'Estimating',
        icon: 'assets/estimating_app.ico',
        link: 'estimatingAppUrl',
      },
      {
        text: 'Costing',
        icon: 'assets/PO_app.ico',
        link: 'purchaseOrdersAppUrl',
      },
      {
        text: 'Config',
        icon: 'assets/conf_app.ico',
        link: 'configAppUrl',
      },
      {
        text: 'Admin',
        icon: 'assets/admin_app.ico',
        link: 'adminAppUrl',
      },
    ],
  },
]
